import React, { useState, useEffect } from "react";
import TicketLeftHOC from "../Common/LeftHocFolder/TicketLeftHOC";
import TicketTemplateTopNav from "../Common/LeftHocFolder/TicketTemplateTopNav";
import Cookies from "js-cookie";
import { BASE_URL } from "../../constants/config";
import axios from "axios";
// ticketSteps={ticketSteps} emptyTicketSteps={emptyTicketSteps}

function TicketTemplate({ children, heirarchySteps, hierarchyAssitantSteps, hierarchyEmptySteps,
  templateSteps,emptyTemplateSteps,ticketSteps,emptyTicketSteps }) {
  const [photo, setPhoto] = useState('')
  const [memberDetails, setMemberDetails] = useState({})
  const [names, setNames] = useState([]);
  const [notificationCount,setNotificationCount] = useState('')


  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", {
        headers: headers,
      });
      const data = response.data.data;

      setPhoto(data.org_details.orgDetails)

      setNotificationCount(data.notification_count)
      // setPhoto(data.profile_details.photo);
      setMemberDetails(data.profile_details);
      // const data = response.data.data;
      // await setUpPage()
      // Save memberDetails and starredDepartments to local storage
      localStorage.setItem(
        "orgDetails",
        JSON.stringify(data.org_details.orgDetails)
      );
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(data.profile_details)
      );
      localStorage.setItem(
        "starredDepartments",
        JSON.stringify(data.star_depart)
      );

      // setPhoto(data.profile_details.photo);
      // setMemberName(data.profile_details.fullName);
      // setNames(data.star_depart);

    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  useEffect(() => {
    const storedOrgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    const storedMemberDetails = JSON.parse(
      localStorage.getItem("memberDetails")
    );
    const storedStarredDepartments = JSON.parse(
      localStorage.getItem("starredDepartments")
    );


    if (storedOrgDetails) {
      // setOrgPhoto(storedOrgDetails.logo);
      // setCompanyName(storedOrgDetails.name);
    }
    if (storedMemberDetails) {
      // setPhoto(storedMemberDetails.photo);
      // setMemberName(storedMemberDetails.fullName);
    }

    if (storedStarredDepartments) {
      setNames(storedStarredDepartments);
    }

  }, []);

const handleMakeZero = () =>{
  setNotificationCount(0)
}
  return (

    <div>
      <TicketTemplateTopNav
        photo={photo.logo}
        company={photo.name}
        memberPhoto={memberDetails.photo}
        memberName={memberDetails.fullName}
        memberEmail={memberDetails.email}
        notificationCount={notificationCount} oncount={handleMakeZero} />
      <div style={{ display: 'flex' }}>
        <TicketLeftHOC staredDept={names} heirarchySteps={heirarchySteps}
          hierarchyAssitantSteps={hierarchyAssitantSteps} hierarchyEmptySteps={hierarchyEmptySteps}
          templateSteps={templateSteps} emptyTemplateSteps={emptyTemplateSteps} ticketSteps={ticketSteps}
           emptyTicketSteps={emptyTicketSteps} />
        {children}
      </div>
    </div>



  );
}

export default TicketTemplate;
