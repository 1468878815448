import React, { useState } from "react";
import "./TicketView.css";
import {
    PHOTO_LINK,
    ASSET_PREFIX_URL,
    BASE_URL,
    DEFAULT_PHOTO,
} from "../../constants/config";
import axios from "axios";
import Cookies from "js-cookie";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import NameProfile from "../Common/NameProfile/NameProfile";

function ChangesPopUp({
    onClose,
    type,
    ticketId,
    prevData,
    newData,
    saveBtn,
    onUpdateDetails,
    responseData,
    attachmentDelete, deleteAttachment,
    attachmentKey
}) {
    console.log(prevData)
    console.log(newData)
    let ticketTitle
    if (type === 'ec-template') {
        ticketTitle = responseData.data.defaultFieldsData.ec_title.value;
    }
    if (type === 'ec-deviation') {
        ticketTitle = responseData.data.defaultFieldsData.deviation_title.value;
    }  if (type === 'template') {
        ticketTitle = responseData.data.defaultFieldsData.ticket_title.value;
    }

    const prevDataKeys = Object.keys(newData);
    for (const key of prevDataKeys) {
        if (prevData[key].hasOwnProperty("inputType")) {
            //   return prevData[key].value;
        }
    }

    const handleCancel = () => {
        onClose()
        // onUpdateDetails()
    }
    const HandleSave = async () => {
        try {
            let url;
            if (type === 'template') {
                url = `${BASE_URL}/v1/ticket/edit-ticket`;
            }

            if (type === 'ec-template') {

                url = `${BASE_URL}/v1/ec-template/edit-ec-ticket`;
            }
            if (type === 'ec-deviation') {

                url = `${BASE_URL}/v1/ec-deviations/edit-ec-ticket`;
            }


            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let membersSet
            if (type === 'template') {
                membersSet = [
                    responseData.data.defaultFieldsData.assignee.value.member_id,
                    ...responseData.data.defaultFieldsData.watchers.value.map(
                        (item) => item.member_id
                    ),
                ];
            }



            let keyName;
            if (type === 'ec-template') {
                keyName = 'ec_id';
            } else if(type === 'ec-deviation') {
                keyName = 'dev_id';
            }else{
                keyName = 'ticket_id'
            }

            await axios.post(
                url,
                {
                    [keyName]: ticketId,
                    prev_obj: JSON.stringify(prevData),
                    new_obj: JSON.stringify(newData),
                    member_ids: membersSet,
                    ticketTitle,
                },
                { headers }
            );


            if (attachmentDelete === true) deleteAttachment(attachmentKey)
            onClose();
            window.location.reload();

            //  onUpdateDetails()
            saveBtn(false);
        } catch (error) {
            console.log(error);
        }
    };



    const getKeyType = () => {
        const keys = Object.keys(prevData);

        if (keys.includes("security")) {
            return "security";
        } else if (keys.includes("priority")) {
            return "priority";
        } else if (keys.includes("due_date")) {
            return "due_date";
        } else if (keys.includes("owner")) {
            return "owner";
        } else if (keys.includes("assignee")) {
            return "assignee";
        } else if (keys.includes("description")) {
            return "description";
        } else if (keys.includes("ticket_title")) {
            return "ticket_title";
        } else if (keys.includes("watchers")) {
            return "watchers";
        } else if (keys.includes("attachment")) {
            return "attachment";
        } else if (keys.includes("text")) {
            return "text";
        }

        // Add more cases for other possible keys if needed
        return "unknown"; // Default case if none of the keys match
    };
    let fieldName = null;
    let value = null;
    let newValue = null;
    let photo = '';
    let fullName = null;
    let newPhoto = '';
    let newFullName = null;
    let multiValue = null;

    let startDate = null;
    let prevStartDate = null;
    let endDate = null;
    let prevEndDate = null;
    const keyType = getKeyType();
    for (const key in prevData) {
        if (
            ["text", "number", "paragraph", "link", "single_select", "date"].includes(
                prevData[key].inputType
            ) &&
            prevData[key].fieldName !== "Security" &&
            newData[key].fieldName !== "Priority"
        ) {
            // If the inputType matches the specified types, store fieldName and value
            fieldName = prevData[key].fieldName;
            value = prevData[key].value;
            // Exit the loop after the first matching inputType is found
        } else if (prevData[key].value.hasOwnProperty("end")) {
            fieldName = prevData[key].fieldName;
            prevEndDate = prevData[key].value.end;
        } else if (prevData[key].value.hasOwnProperty("start")) {
            fieldName = prevData[key].fieldName;
            prevStartDate = prevData[key].value.start;
        } else if (prevData[key].inputType === "person") {

            fieldName = prevData[key].fieldName;
            photo = prevData[key].value.photo;
            fullName = prevData[key].value.fullName;
        } else if (prevData[key].inputType === "multi_select") {
            fieldName = prevData[key].fieldName;

            if (fieldName === 'Select part numbers') {
                multiValue = prevData[key].value.map((item) => item.part_number);
            }else if(fieldName === 'Select EC') {
                multiValue = prevData[key].value.map((item) => item.ec_title);
            }else {
                multiValue = prevData[key].value.map((item) => item);
            }

        }
    }

    for (const key in newData) {
        if (
            ["text", "number", "paragraph", "link", "single_select", "date"].includes(
                newData[key].inputType
            ) &&
            newData[key].fieldName !== "Security" &&
            newData[key].fieldName !== "Priority"
        ) {
            // If the inputType matches the specified types, store newValue
            fieldName = newData[key].fieldName;
            console.log(fieldName)
            newValue = newData[key].value;

            break; // Exit the loop after the first matching inputType is found
        } else if (newData[key].value.hasOwnProperty("end")) {
            fieldName = newData[key].fieldName;
            endDate = newData[key].value.end;
        } else if (newData[key].value.hasOwnProperty("start")) {
            fieldName = newData[key].fieldName;
            startDate = newData[key].value.start;
        } else if (newData[key].inputType === "person") {

            fieldName = newData[key].fieldName;
            newPhoto = newData[key].value.photo;
            newFullName = newData[key].value.fullName;

        } else if (newData[key].inputType === "multi_select") {
            fieldName = newData[key].fieldName;
            if (fieldName === 'Select part numbers') {
                value = newData[key].value.map((item) => item.part_number);
            }else if(fieldName === 'Select EC') {
                value = newData[key].value.map((item) => item.ec_title);
            } else {
                value = newData[key].value.map((item) => item);
            }
            // value = newData[key].value.map((item) => item);
        }
    }

    // Log fieldName and value to the console

    return (
        <div className="change-details-page">
            <div className="change-details-cont">
                <div>

                    {(fieldName && fieldName !== 'Attchments') ? (
                        <span className="change-details-title">Change {fieldName}?</span>
                    ) : (
                        <span className="change-details-title">
                            {fieldName !== 'Attchments' ? 'Update Attachments' : `Change ${newData[keyType].fieldName}`}
                        </span>
                    )}

                </div>

                <div>
                    <div className="change-details-description">
                        <span>This would result in the following action:</span>
                    </div>

                    <div className="change-details-fieldName">
                        {fieldName ? (
                            <span>{fieldName}</span>
                        ) : (
                            <span>{prevData[keyType].fieldName}</span>
                        )}
                    </div>
                    {(keyType === "priority" ||
                        keyType === "security" ||
                        keyType === "due_date") && (
                            <div className="change-details-div">
                                <span className="change-details-previous">
                                    {prevData[keyType].value}
                                </span>
                                <img
                                    src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                    alt=""
                                    width="12px"
                                    className="arrow-watchers"
                                />
                                <span className="change-details-newData">
                                    {newData[keyType].value}
                                </span>
                            </div>
                        )}
                    {(keyType === "owner" || keyType === "assignee") && (
                        <div className="change-details-div">
                            {/* Render profile picture and name for prevData */}

                            <NameProfile userName={prevData[keyType].value.fullName} memberPhoto={prevData[keyType].value.photo} width="26px" fontweight='500' />
                            &nbsp;

                            <span className="change-details-name">{prevData[keyType].value.fullName}</span>
                            <img
                                src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                alt=""
                                width="12px"
                                className="arrow-watchers"
                            />
                            {/* Render profile picture and name for newData */}

                            <NameProfile userName={newData[keyType].value.fullName} memberPhoto={newData[keyType].value.photo} width="26px" fontweight='500' />
                            &nbsp;<span className="change-details-name">{newData[keyType].value.fullName}</span>
                        </div>

                    )}
                    {
                        keyType === "watchers" && (
                            // <div className='change-details-div'>

                            <div className="change-details-div ">
                                {/* Render images for prevData */}
                                {prevData[keyType].value.map((item) => (
                                    <React.Fragment key={item.id}>

                                        <NameProfile userName={item.fullName} memberPhoto={item.photo} width="26px" fontweight='500' />

                                    </React.Fragment>


                                ))}

                                <img
                                    src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                    alt=""
                                    width="12px"
                                    className="arrow-watchers"
                                />

                                {/* Render images for newData */}
                                {newData[keyType].value.map((item) => (
                                    <React.Fragment key={item.id}>

                                        <NameProfile userName={item.fullName} memberPhoto={item.photo} width="26px" fontweight='500' />

                                    </React.Fragment>
                                ))}
                            </div>
                        )

                        // &nbsp;&nbsp;<img src={`${ASSET_PREFIX_URL}changes-arrow.svg`} alt='' width='12px' />
                        // <span className='change-details-newData'>Watchers has been Added/Removed</span>
                        // </div>
                    }
                    {keyType === "attachment" && (
                        <div className="change-details-div">
                            <span className="change-details-newData">
                                {newData[keyType].value.length > 0
                                    ? newData[keyType].value[newData[keyType].value.length - 1]
                                        .action
                                    : ""}
                            </span>
                            <img
                                src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                alt=""
                                width="12px"
                                className="arrow-watchers"
                            />
                            <span className="change-details-newData">
                                {newData[keyType].value.length > 0
                                    ? newData[keyType].value[newData[keyType].value.length - 1]
                                        .name
                                    : ""}
                            </span>
                        </div>
                    )}

                    {(keyType === "description" || keyType === "ticket_title") && (
                        <div className="change-details-div-dec">
                            <div className="old-text-div">
                                <span>{prevData[keyType].value}</span>
                            </div>

                            <img
                                src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                alt=""
                                width="12px"
                                className="arrow-watchers"
                            />
                            <div className="new-text-div">
                                <span>{newData[keyType].value}</span>
                            </div>
                        </div>
                    )}
                    {/* {(keyType==='text'||prevData[keyType].hasOwnProperty('inputType'))&&
                <div className='change-details-div-dec'>
                     <div className='new-text-div'>
                     <span >{prevData[keyType].value}</span>
                     </div>
                   
                                                      <img src={`${ASSET_PREFIX_URL}changes-arrow.svg`} alt='' width='12px' className='arrow-watchers'/>
                    <div className='new-text-div'>
                    <span >{newData[keyType].value}</span>
                    </div>
                   
                </div>
                } */}
                    {fieldName &&
                        newValue &&
                        keyType !== "ticket_title" &&
                        keyType !== "description" &&
                        keyType !== "due_date" && (
                            <div className="change-details-div-dec">
                                <div className="old-text-div">
                                    <span>{value}</span>
                                </div>

                                <img
                                    src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                    alt=""
                                    width="12px"
                                    className="arrow-watchers"
                                />
                                <div className="new-text-div">
                                    <span>{newValue}</span>
                                </div>
                            </div>
                        )}
                    {fieldName && multiValue && (
                        <div className="change-details-div">
                            <span className="change-details-newData ">{multiValue + " "}</span>
                            <img
                                src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                alt=""
                                width="12px"
                                className="arrow-watchers"
                            />
                            <span className="change-details-newData">{value + " "}</span>
                        </div>
                    )}
                    {fieldName &&
                        (photo || photo === '') &&
                        (newPhoto || newPhoto === '') &&
                        fullName &&
                        newFullName &&
                        keyType !== "assignee" && (
                            <div className="change-details-div">


                                <NameProfile userName={fullName} memberPhoto={photo} width="26px" fontweight='500' />



                                &nbsp;
                                <span className="change-details-previous">{fullName}</span>
                                <img
                                    src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                    alt=""
                                    width="12px"
                                    className="arrow-watchers"
                                />

                                <NameProfile userName={newFullName} memberPhoto={newPhoto} width="26px" fontweight='500' />
                                &nbsp;
                                <span className="change-details-newData">{newFullName}</span>
                            </div>
                        )}

                    {/* {(startDate && !endDate) &&
                        <>
                            <span>start date is changing</span>
                            <div className='change-details-div'>

                                <span className='change-details-previous'>{prevStartDate}</span>
                                                                  <img src={`${ASSET_PREFIX_URL}changes-arrow.svg`} alt='' width='12px' className='arrow-watchers'/>

                                <span className='change-details-newData'>{startDate}</span>
                            </div>
                        </>

                    } */}
                    {endDate && (
                        <>
                            <span>
                                {" "}
                                changing the dates in{" "}
                                <span style={{ fontWeight: "600" }}>{fieldName}</span>
                            </span>
                            {/* <div className='change-details-div'>

                                <span className='change-details-previous'>{prevEndDate}</span>
                                                                  <img src={`${ASSET_PREFIX_URL}changes-arrow.svg`} alt='' width='12px' className='arrow-watchers'/>

                                <span className='change-details-newData'>{endDate}</span>
                            </div> */}
                        </>
                    )}
                </div>
                <div className="change-details-buttons">
                    <button className="change-details-change" onClick={HandleSave}>
                        Yes, change
                    </button>
                    <button className="change-details-cancel" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ChangesPopUp;
