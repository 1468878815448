import React, { useState, useEffect } from 'react';
import './OverView.css';
import axios from 'axios'
import { BASE_URL, ASSET_PREFIX_URL } from '../../constants/config';
import Cookies from "js-cookie";
import Loading from '../Common/Loading/Loading';


function Overview() {

  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const fetchOnBoardStatus = async () => {
    const headers = {
      'x-auth-token': Cookies.get('token')
    };
    try {
      setLoading(true)
      const response = await axios.get(`${BASE_URL}/v1/onBoard/onboard-status`, { headers: headers });
      const responseData = response.data.data;
    
      if (response.data.meta.code == '200' && response.data.meta.message == 'SUCCESS') {
        setUsername(responseData.member.name);
        if (responseData.hierarchy_count > 0) {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[2].completed = true;
            return updatedSteps;
          });

        } else {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[2].completed = false;
            return updatedSteps;
          });

        }
        if (responseData.member_count > 1) {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[1].completed = true;
            return updatedSteps;
          });

        } else {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[1].completed = false;
            return updatedSteps;
          });

        }
        if (responseData.template_count > 0) {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[3].completed = true;
            return updatedSteps;
          });

        } else {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[3].completed = false;
            return updatedSteps;
          });

        }
        if (responseData.ticket_count > 0) {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[4].completed = true;
            return updatedSteps;
          });

        } else {
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[4].completed = false;
            return updatedSteps;
          });

        }
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching onBoardStatus:', error);
    }
  };

  useEffect(() => {
    fetchOnBoardStatus();
  }, []);

  const [steps, setSteps] = useState([
    {
      id: 'createAccount',
      label: 'Create your account',
      icon: `${ASSET_PREFIX_URL}login-status.svg`,
      description: 'Create your account',
      completed: true,
      buttonText: 'Continue',
      completedImage: `${ASSET_PREFIX_URL}login-status.svg`,
      processingImage: `${ASSET_PREFIX_URL}login-status.svg`,
      notCompletedImage: `${ASSET_PREFIX_URL}login-status.svg`,
      path: '/login'
    },
    {
      id: 'addMembers',
      label: 'Add your team members',
      icon: `${ASSET_PREFIX_URL}member-status.svg`,
      description: 'Create your account',
      completed: true,
      buttonText: 'Add Members',
      completedImage: `${ASSET_PREFIX_URL}member-added-status.svg`,
      processingImage: `${ASSET_PREFIX_URL}member-status.svg`,
      notCompletedImage: `${ASSET_PREFIX_URL}member-status.svg`,
      path: '/settings?tab=users'
    },
    {
      id: 'setupHierarchy',
      label: 'Setup team hierarchy',
      icon: `${ASSET_PREFIX_URL}hierarchy-process-status.svg`,
      description: 'Quick setup of the hierarchy of your added team \nmembers',
      completed: true,
      buttonText: 'Setup Team',
      completedImage: `${ASSET_PREFIX_URL}hierarchy-added-status.svg`,
      processingImage: `${ASSET_PREFIX_URL}hierarchy-process-status.svg`,
      notCompletedImage: `${ASSET_PREFIX_URL}hierarchy-process-status.svg`,
      path: '/org-home'
    },
    {
      id: 'setupTemplate',
      label: 'Setup ticket template',
      icon: `${ASSET_PREFIX_URL}ticket-status.svg`,
      description: 'Set the templatefor ticket creation& get \nstarted with Marathon',
      completed: true,
      buttonText: 'Setup Ticket',
      completedImage: `${ASSET_PREFIX_URL}template-added-status.svg`,
      processingImage: `${ASSET_PREFIX_URL}template-process-status.svg`,
      notCompletedImage: `${ASSET_PREFIX_URL}ticket-status.svg`,
      path: '/template'
    },
    {
      id: 'createFirstTicket',
      label: 'Create first ticket',
      icon: `${ASSET_PREFIX_URL}firstticket-status.svg`,
      description: 'Create your account',
      completed: true,
      buttonText: 'Create Ticket',
      completedImage: `${ASSET_PREFIX_URL}ticket-process-status.svg`,
      processingImage: `${ASSET_PREFIX_URL}ticket-process-status.svg`,
      notCompletedImage: `${ASSET_PREFIX_URL}firstticket-status.svg`,
      path: '/all-list'
    },
  ]);


  const handleStepCompletion = (stepId) => {
    const updatedSteps = steps.map((step, index) => {
      if (step.id === stepId) {
        return { ...step, completed: true };
      }

      return step;
    });

    setSteps(updatedSteps);
  };

  const completedSteps = steps.filter(step => step.completed);
  const totalSteps = steps.length;
  const statusTrackerWidth = (completedSteps / totalSteps) * 100;


  const gradientColors = `linear-gradient(90deg, #black ${statusTrackerWidth}%, black ${statusTrackerWidth}%)`;
  return (
    <>
     {/* <TicketTemplateTopNav /> */}
      {
        loading ? <Loading /> :
          <div className='overview-page'>
            <div className='overview-headers'>
              <span className='start-overview'>Let’s get you started, {username}</span>
              {/* <span className='start-overview-sub'>Take a few minutes and get the best out of Marathon</span> */}
            </div>
            <div className='overview-status-track'>
              <div className='overview-marathon-setup'>
                <span className='marathon-text'>Marathon Setup</span>
                <span className='marathon-steps'>{completedSteps.length} of {totalSteps} steps</span>
              </div>
              {/* <div className='status-tracker' style={{ background: gradientColors }}>

              </div> */}
              <div className='overview-status-checks'>
                {steps.map((step, index) => (
                  <div className='overview-login-check' key={step.id}>
                    <div className='overview-login-details'>
                      <img
                        src={
                          step.completed
                            ? step.completedImage
                            : (completedSteps.some(completedStep => completedStep.id === step.id)
                              ? step.processingImage
                              : step.notCompletedImage)
                        }
                        alt={step.completed ? 'Completed' : 'Not Completed'}
                      />
                      <div className='overview-details'>
                        <span className='overview-details-head'>{step.label}</span>
                        {/* {step.description.split('\n').map((paragraph, index) => (
                          <span className='overview-details-sub' key={index}>{paragraph}</span>
                        ))} */}
                      </div>
                    </div>
                    <img src={step.completed ? `${ASSET_PREFIX_URL}status-checked.svg` : ''} alt='' />
                    {(!step.completed && (completedSteps.length === index)) && (
                      <a href={step.path}>
                        <button 
                        // onClick={() => handleStepCompletion(step.id)} 
                        className='overview-status-button'>
                          {step.buttonText}
                        </button>
                      </a>

                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
      }
    </>

  );
}

export default Overview;
