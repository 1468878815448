import React, { useState, useEffect, useRef } from "react";
import {
    BASE_URL,
    ASSET_PREFIX_URL,

} from "../../constants/config";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import Cookies from "js-cookie";
import axios from "axios";
 
import EcApproveList from "./EcApproveList";
import ErrorPartsPopUp from "./ErrorPartsPopUp";

function PartsBeforePopUp(partsToBeAdded,handleclose, errorParts, ticketId, sequence_id, type, onclose,errorPopUpPlose) {
    // console.log(partsToBeAdded)
  
    const [OpenApproval, setOpenApproval] = useState(false);
    const [errorOpenPopUp, setErrorOpenPopUp] = useState(false);

    // Update partDetails when partsToBeAdded or errorParts change
    const [partsToBeAddedPop, setPartsToBeAddedPop] = useState([]);
    const [partsToBeAddedErrorPop, setPartsToBeAddedErrorPop] = useState([]);
    // Update partDetails when partsToBeAdded or errorParts change
    useEffect(()=>{
        fecthPartsToBeAdded()
    },[])
    const fecthPartsToBeAdded = async()=>{
        try {
            // setLoading(true)
            const headers = {
              "x-auth-token": Cookies.get("token"),
            };
      
      
            const approvalResponse = await axios.get(
              `${BASE_URL}/v1/ec-template/validate-part-num-mapping`,
              {
                headers,
                params: {
                  [partsToBeAdded.type === 'ec-template' ? 'ec_id' : 'dev_id']: partsToBeAdded.ticketId
                }
              }
            );
      
           
           
            console.log(approvalResponse.data.data)
           
            
              setPartsToBeAddedPop(approvalResponse.data.data.parts_to_be_added); 
              setPartsToBeAddedErrorPop(approvalResponse.data.data.err_parts)
            //  setLoading(false)
          } catch (error) {
            console.log(error)
            // setLoading(false)
          }
    }
    // Function to log partDetails on button click
    const handleGetApproval = async() => {
        console.log("Part details:", partsToBeAddedPop);
        console.log(partsToBeAddedPop.map(item=>item.part_number))
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
              };
           
            if (partsToBeAdded.type === 'ec-template') {
              const getpartsDetails = await axios.post(
                `${BASE_URL}/v1/ec-template/create-part-num-mapping`,
                {
                  enchange_id: partsToBeAdded.ticketId, sequence_id: partsToBeAdded.sequence_id,
                  
                  part_list: partsToBeAddedPop.map(item=>item.part_number),
  
                },
                { headers: headers }

                
              );
              // if(getpartsDetails.data.meta.message === 'SUCCESS'){
                if (partsToBeAddedErrorPop.length > 0) {
            
                    setErrorOpenPopUp(true)
                    // partsToBeAdded.handleclose()
                } else {
                    setOpenApproval(true)
                }
            // }else{
            //     console.log('sdgfsd')
            // }
              
            } else if (partsToBeAdded.type === 'ec-deviation') {
              const getpartsDetails = await axios.post(
                `${BASE_URL}/v1/ec-deviations/create-part-number-dev`,
                {
                  deviation_id: partsToBeAdded.ticketId, sequence_id: partsToBeAdded.sequence_id,
                  part_list:partsToBeAddedPop.map(item=>item.part_number),
  
                },
                { headers: headers }
              );
              // if(getpartsDetails.data.meta.message === 'SUCCESS'){
                if (partsToBeAddedErrorPop.length > 0) {
                  console.log('2222')
                    setErrorOpenPopUp(true)
                    // partsToBeAdded.handleclose()
                } else {
                    setOpenApproval(true)
                }
            // }else{
            //     console.log('sdgfsd')
            // }
            }
           
           
          } catch (error) {
            console.log(error)
          }
        // partsToBeAdded.handleclose()
       
        
        // partsToBeAdded.handleclose()
        // Here you can perform additional actions, such as sending the part details for approval
    };
    return (
        <>
            <div className="notification-page">
                <div className="notification-div">
                    <div className="border-bot-partsadd">
                        <div className="part-number-popup-text">
                            {/* <img src={`${ASSET_PREFIX_URL}bell_icon.png`} alt=""/> */}
                            <span>Parts to be added before {`${partsToBeAdded.type === 'ec-template' ? 'EC' : 'Deviation'}`} approval</span>

                        </div>

                        <PopupCloseButton
                         handleClose={partsToBeAdded.handleclose} 
                        />
                    </div>
                    <div className="part-number-popup-border"></div>


                    <div
                        className="parts-add-before"
                    // onScroll={handleScroll}
                    // ref={containerRef}
                    >
                        {partsToBeAddedPop.map((item, index) => (
                            <div
                                // key={item.part_id} 
                                className="part-numbers-views">
                                <div className="part-numbers-views-details">

                                    <div className="part-numbers-views-content">

                                    {/* <span className="part-number-view-number" style={{ cursor: 'pointer' }}
                                      >{item.part_number}</span> */}
                                      <a href={`/part-view/${item.part_number}`} target='_blank' className='link-to-view-ec'>{item.part_number}</a>
                                    <span className="part-number-view-title">{item.part_title}</span>
                                        
                                    </div>

                                </div>
                            </div>
                        ))}
                        


                    </div>
                    <button className="parts-add-before-btn" onClick={handleGetApproval}>Add these part(s) to {`${partsToBeAdded.type === 'ec-template' ? 'EC' : 'Deviation'}`}</button>

                </div>
            </div>
            {errorOpenPopUp && 
            <ErrorPartsPopUp errorParts={partsToBeAdded.errorParts} handleclose={partsToBeAdded.errorPopUpPlose}
            closePartsAdd={partsToBeAdded.handleclose} ticketId={partsToBeAdded.ticketId} type={partsToBeAdded.type}/>}
            {OpenApproval && <EcApproveList sequence_id={partsToBeAdded.sequence_id} type={partsToBeAdded.type} ticketId={partsToBeAdded.ticketId}
            onclose={partsToBeAdded.onclose}  />}
        </>

    )
}

export default PartsBeforePopUp