export const BASE_URL = "https://marathon-os.com"
export const DEFAULT_PHOTO =  "https://marathon-web-assets.s3.ap-south-1.amazonaws.com/profilelogodefault.png"
export const PHOTO_LINK = "https://member-images.s3.ap-south-1.amazonaws.com/"
export const DEFAULT_COMPANY_LINK = "https://member-images.s3.ap-south-1.amazonaws.com/upload_1695283928.png"
export const ASSET_PREFIX_URL = "https://marathon-web-assets.s3.ap-south-1.amazonaws.com/"
export const TICKET_ATTACHMENT_BUCKET = 'member-images'
export const GOOGLE_CLIENT_ID = '489950323098-a8i5stost392oousg6k1t3u4tbupi8tq.apps.googleusercontent.com'
export const MICROSOFT_CLIENT_ID = "8ef57ff0-a2bf-423d-8400-fe3fdf571177"



