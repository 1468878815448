import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import "./PartNumberView.css";
import {

  PHOTO_LINK,
  TICKET_ATTACHMENT_BUCKET,
  ASSET_PREFIX_URL, BASE_URL
} from "../../constants/config";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Loading from './../Common/Loading/Loading';
import NameProfile from "../Common/NameProfile/NameProfile";


function AssetNumberView() {
  const [selectMember, setSelectMember] = useState([])
  const [partDetails, setPartDetails] = useState({})
  const [photo, setPhoto] = useState('');
  const [fullName, setFullName] = useState('')
  const [createdDate, setCreatedDate] = useState('');
  const [loading, setLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [fileSizeError, setFileSizeError] = useState('');
  const [partNumber, setPartNumber] = useState('');
  const [shouldEdit, setShouldEdit] = useState(true);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [customDetails, setCustomDetails] = useState({});
  const [editingValue, setEditingValue] = useState({
    asset_title: '',
    description: '',
    quantity: '',
  });
  const [editingField, setEditingField] = useState({
    asset_title: false,
    description: false,
    quantity: false,
  });
  const nav = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editedField, setEditedField] = useState(null);


  const fetchMembers = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/setting/get-mem", {
        headers: headers,
      });
      setSelectMember(response.data.data.memberList);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  useEffect(() => {
    partNumberDetails();
  }, [id]);

  const partNumberDetails = async () => {

    try {
      setLoading(true)

      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const partNumberDetails = await axios.get(
        `${BASE_URL}/v1/asset/asset-details-page/${id}`,
        { headers }
      );
      console.log(partNumberDetails)
      setAttributeOptions(partNumberDetails.data.data.asset_details.attribute_data);
      setCustomDetails(partNumberDetails.data.data.asset_details.custom_data)
      setPhoto(partNumberDetails.data.data.asset_details.created_by.photo);
      setFullName(partNumberDetails.data.data.asset_details.created_by.fullName);
      setPartDetails(partNumberDetails.data.data.asset_details);
      setPartNumber(partNumberDetails.data.data.asset_details.part_number)

      setUploadedFiles(partNumberDetails.data.data.asset_details.attachments)



      setShouldEdit(partNumberDetails.data.data.should_edit)

      const createdAtDate = new Date(partNumberDetails.data.data.asset_details.createdAt);
      const formattedDate = createdAtDate.toISOString().split('T')[0];
      setCreatedDate(formattedDate)
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    handleFile(file);
  };
  const upload = () => {
    document.getElementById("fileupld").click();
  };
  const handleFileDrop = async (event) => {
    event.preventDefault();
    // setDragging(false);
    if (shouldEdit) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];

        handleFile(file);
      }
    }

  };
  const handleDragOver = (event) => {
    event.preventDefault();
    // setDragging(true);
  };
  const getFileIcon = (fileName) => {
    // console.log(fileName)
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div

          style={{ overflow: 'hidden', border: 'none', }} className="extension-type-div">
          <iframe
            src={PHOTO_LINK + fileName}
            title="CSV Preview"
          //   width= '75px'
          // height= '75px'
          // border= 'none'
          // style={{ overflowY: 'hidden', border: 'none',padding:'0px' }}
          // scrolling="no"
          />
        </div>

      );
    }
    else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xlsFile.png`}
          alt="Excel"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "xml") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xmlFile.png`}
          alt="XML"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "jpg" || extension === 'png' || extension === 'jpeg' || extension === 'svg') {
      return (
        <img src={PHOTO_LINK + fileName} alt=""
          className="png-img-div" />
      );
    }
    else if (extension === "pptx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}pptxFile.png`}
          alt="ppt"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "csv") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}csvFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "docx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}wordFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else {
      return <img src={`${ASSET_PREFIX_URL}filesImage.png`} alt="File" width="75px"
        height="75px" />;
    }
  };

  const handleFile = async (file) => {
    setFileSizeError('');
    setUploadError('');
    const fileSizeMB = file.size / (1024 * 1024);  // size in MB
    if (fileSizeMB > 2) {
      setFileSizeError('File size cannot be more than 2 MB');  // Notify the user
      return;  // Exit the function
    }
    //fetch pre signed URL
    setIsLoading(true);
    try {
      const headers = {
        'x-auth-token': Cookies.get('token')
      };
      const preSignedURL = await axios.post(`${BASE_URL}/v1/member/get-presigned-url`, { bucket_name: TICKET_ATTACHMENT_BUCKET, file: file.name }, { headers: headers })
      if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
        //upload to s3
        await axios.put(preSignedURL.data.data.url, file, {
          headers: {
            'Content-Type': file.type
          }
        });
        // When uploading a new file, add it to the attachments array

        // If editing, send the updated attachments array along with other updates
        const updatedAttachments = [...partDetails.attachments, { name: file.name, key: preSignedURL.data.data.key }];
        await axios.post(
          `${BASE_URL}/v1/asset/edit-asset-details`,
          { asset_id: partDetails.asset_number, updates: { attachments: updatedAttachments } },
          { headers }
        );
        partNumberDetails()
        // Handle the response as needed

      } else {
        alert('Error generating signed URL')
      }
    } catch (e) {
      setUploadError('Error in uploading file');
    } finally {
      // Set isLoading back to false after upload (whether success or failure)
      setIsLoading(false);
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "180px",
      backgroundColor: "transparent",
      border: "none",
      outline: "none", // Remove the border
      boxShadow: "none", // Remove any box shadow
      //  border: 1px solid  #EDF2F7;
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the line (border)
    }),

    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white", // Set the background color
      color: state.isSelected ? "black" : "inherit", // Set the text color
      cursor: "pointer", // Add cursor pointer on hover
      ":hover": {
        background: "lightgray", // Change the background color on hover
      },
    }),

    // You can define styles for other parts of the Select component here
  };
  const handleBack = () => {
    nav('/assets-parts-list');
  };






  const handleUpdate = async (key, value, updateType) => {
    console.log(key)
    console.log(value)
    // Create an object with the provided key and value
    let updatedDetails
    if (updateType) {
      // If updateType is true, update the attribute_data
      updatedDetails = {
        'attribute_data': attributeOptions.map(item => {
          if (item.title === key) {
            return { ...item, value };
          }
          return item;
        })
      };
    } else {
      // If updateType is false, update the key directly
      updatedDetails = {
        [key]: value
      };
    }
    console.log(updatedDetails)
    try {
      const headers = {
        'x-auth-token': Cookies.get('token')
      };
      const response = await axios.post(
        `${BASE_URL}/v1/asset/edit-asset-details`, { asset_id: partDetails.asset_number, updates: updatedDetails }, { headers }
      )
      setEditingField((prevState) => ({
        ...prevState,
        [key]: false,
      }));
      partNumberDetails()
      console.log(response)

    } catch (error) {
      console.log(error)
    }
    console.log(updatedDetails);

  };
  const deleteAttachment = async (key) => {

    try {

      const headers = {
        'x-auth-token': Cookies.get('token'),
      };
      await axios.post(BASE_URL + '/v1/asset/delete-asset-attachment', { assetId: partDetails._id, key }, { headers: headers });

      partNumberDetails()
    } catch (error) {
      console.log(error);
    }


  }
  const handleEditing = (e, section) => {

    setEditingField((prevState) => ({
      ...prevState,
      [section]: true,
    }));


  }
  const handleCancelEditing = (e, section) => {

    setEditingField((prevState) => ({
      ...prevState,
      [section]: false,
    }));


  }
  const handleEngineeringChange = (e, section) => {
    console.log(section, ':', e.target.value)
    e.stopPropagation()
    setEditingValue((prevState) => ({
      ...prevState,
      [section]: e.target.value,
    }));
    console.log((prevState) => ({
      ...prevState,
      [section]: e.target.value,
    }))

  }
  const handlePersonSelectChange = (fieldName, selectedOption) => {


    const fieldValue = {
      photo: selectedOption.label.props.children[0].props.memberPhoto || '',
      member_id: selectedOption.value,
      fullName: selectedOption.label.props.children[1],
    };

    const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
    const updatedFormData = {
      ...formData,
      [transformedFieldName]: fieldValue,
    };
    console.log(fieldValue)
    setFormData(updatedFormData);
  };

  const renderFields = (fields) => {
    if (!fields) return null;

    return Object.keys(fields).map((fieldKey) => {
      const field = fields[fieldKey];
      if (!field.value) return null;

      return (
        <div key={fieldKey} className='part-view-inputs'>
          <span className='supply-custom-label-span'>{field.fieldName}</span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            {editedField === fieldKey ? (
              <>
                {(field.inputType === 'text' || field.inputType === 'email' || field.inputType === 'gst' ||
                  field.inputType === 'team' || field.inputType === 'pincode') && (
                    <>
                      <input
                        type='text'
                        placeholder={field.placeholder || ''}
                        value={formData[fieldKey] || field.value || ''}
                        onChange={(e) => handleChange(fieldKey, e.target.value)}
                      />
                      <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                      <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    </>
                  )}

                {(field.inputType === 'paragraph') && (
                  <>
                    <textarea
                      className='supply-textarea'
                      placeholder={field.placeholder || ''}
                      value={formData[fieldKey] || field.value || ''}
                      onChange={(e) => handleChange(fieldKey, e.target.value)}
                    />
                    <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                  </>
                )}

                {field.inputType === 'single_select' && (
                  <>
                    <Select
                      styles={customStyles}
                      options={field.options.map(option => ({ value: option, label: option }))}
                      value={{ value: formData[fieldKey] || field.value, label: formData[fieldKey] || field.value }}
                      onChange={(selectedOption) => handleChange(fieldKey, selectedOption.value)}
                    />
                    <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                  </>
                )}
                {field.inputType === 'date' && (
                  <>
                    <input
                      type='date'
                      placeholder={field.placeholder || ''}
                      value={formData[fieldKey] || field.value || ''}
                      onChange={(e) => handleChange(fieldKey, e.target.value)}
                    />
                    <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                  </>
                )}
                {field.inputType === 'person' && (
                  <>
                    <Select
                      placeholder={field.placeholder}
                      onFocus={fetchMembers}
                      styles={customStyles}
                      options={selectMember.map((member) => ({
                        value: member._id,
                        label: (
                          <div
                            className="member-option"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <NameProfile
                              userName={member.fullName}
                              width='26px'
                              memberPhoto={member.photo}
                              fontweight='500'
                            />
                            {member.fullName}
                          </div>
                        ),
                      }))}
                      onChange={(selectedOption) => {
                        handlePersonSelectChange(field.fieldName, selectedOption);
                      }}
                      value={formData[fieldKey] ? {
                        value: formData[fieldKey]._id,
                        label: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {formData[fieldKey].photo ? (
                              <img
                                src={PHOTO_LINK + formData[fieldKey].photo}
                                alt="options"
                                className="owner-image"
                              />
                            ) : (
                              <div className="owner-image-span">
                                <NameProfile userName={formData[fieldKey].fullName} />
                              </div>
                            )}
                            {formData[fieldKey].fullName.length < 15
                              ? formData[fieldKey].fullName
                              : formData[fieldKey].fullName.slice(0, 15) + '...'}
                          </div>
                        ),
                      } : field.value ? {
                        value: field.value._id,
                        label: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {field.value.photo ? (
                              <img
                                src={PHOTO_LINK + field.value.photo}
                                alt="options"
                                className="owner-image"
                              />
                            ) : (
                              <div className="owner-image-span">
                                <NameProfile userName={field.value.fullName} />
                              </div>
                            )}
                            {field.value.fullName.length < 15
                              ? field.value.fullName
                              : field.value.fullName.slice(0, 15) + '...'}
                          </div>
                        ),
                      } : null}
                    />
                    <img
                      onClick={() => handleSaveChanges(fieldKey)}
                      src={`${ASSET_PREFIX_URL}save-details.png`}
                      alt=""
                      width='24px'
                      height='24px'
                      style={{ cursor: 'pointer' }}
                    />
                    <img
                      onClick={handleCancelEdit}
                      src={`${ASSET_PREFIX_URL}cancel-detail.png`}
                      alt=""
                      width='24px'
                      height='24px'
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                )}


                {field.inputType === 'date_range' && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '500px' }}>
                    <div className="type-texts" style={{ width: '290px' }}>
                      <div style={{ width: '200px' }}>
                        <span>Start Date</span>
                        <input
                          placeholder={field.placeholder}
                          type="date"
                          style={{ width: '100%' }}
                          value={formData[fieldKey]?.start || field.value?.start || ''}
                          // value={formData[field.fieldName]?.start || field.value.start}
                          min={new Date().toISOString().split("T")[0]}
                          onChange={(e) =>
                            handleChange(fieldKey, {
                              ...formData[field.fieldName],
                              start: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div style={{ width: '200px' }}>
                        <span>End Date</span>
                        <input
                          type="date"
                          style={{ width: '100%' }}
                          value={formData[fieldKey]?.end || field.value?.end || ''}
                          min={formData[field.fieldName]?.start || field.value?.start}
                          onChange={(e) =>
                            handleChange(fieldKey, {
                              ...formData[field.fieldName],
                              end: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                      <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
                )}

                {field.inputType === 'number' && (
                  <>
                    <input
                      type='number'
                      placeholder={field.placeholder || ''}
                      value={formData[fieldKey] || field.value || ''}
                      onChange={(e) => handleChange(fieldKey, e.target.value)}
                    />
                    <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                  </>
                )}
                {field.inputType === 'link' && (
                  <>
                    <input
                      type='text'
                      placeholder={field.placeholder || ''}
                      value={formData[fieldKey] || field.value || ''}
                      onChange={(e) => handleChange(fieldKey, e.target.value)}
                    />
                    <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                  </>
                )}
                {field.inputType === 'multi_select' && (
                  <>
                    <Select
                      styles={customStyles}
                      isMulti
                      options={field.options.map(option => ({ value: option, label: option }))}
                      value={(formData[fieldKey] || field.value || []).map(val => ({ value: val, label: val }))}
                      onChange={(selectedOptions) => handleChange(fieldKey, selectedOptions.map(option => option.value))}
                    />
                    <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                    <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                  </>
                )}
              </>
            ) : (
              <>
                {field.inputType === 'link' ? (
                  <a
                    href={field.value.includes('http') ? field.value : `https://${field.value}`}
                    target="_blank"
                    className="form-link"
                    style={{ width: '210px', padding: '10px 16px', boxSizing: 'border-box' }}
                  >
                    {field.value}
                  </a>
                ) : field.inputType === 'date_range' ? (
                  <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
                    <input type="text" value={field.value?.start || ''} readOnly />
                    <input type="text" value={field.value?.end || ''} readOnly />
                  </div>
                ) : field.inputType === 'person' ? (
                  <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {field.value.photo ? (
                              <img
                                src={PHOTO_LINK + field.value.photo}
                                alt="options"
                                className="owner-image"
                              />
                            ) : (
                              <div className="owner-image-span">
                                <NameProfile userName={field.value.fullName} />
                              </div>
                            )}
                            {field.value.fullName.length < 15
                              ? field.value.fullName
                              : field.value.fullName.slice(0, 15) + '...'}
                          </div>
                ) : (
                  <input value={formData[fieldKey] || field.value} disabled />
                )}
                {shouldEdit && 
                <img
                onClick={() => handleEdit(fieldKey)}
                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                alt="Edit"
                width="20px"
                height="20px"
                style={{ cursor: 'pointer' }}
              />}
                
              </>
            )}
          </div>
        </div>
      );
    });
  };
  const handleChange = (fieldKey, value) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldKey]: value
    }));

  };


  const handleEdit = (fieldKey) => {
    setEditedField(fieldKey);
  };

  const handleCancelEdit = () => {
    setEditedField(null);
  };

  const handleSaveChanges = async (fieldKey) => {
    console.log(fieldKey)
    const isDefaultField = customDetails.hasOwnProperty(fieldKey);

    const previousData = {
      [fieldKey]: isDefaultField && customDetails[fieldKey]
        ? { ...customDetails[fieldKey] }
        : {}
    };

    const updatedData = {
      [fieldKey]: isDefaultField
        ? { ...customDetails[fieldKey], value: formData[fieldKey] }
        : { value: formData[fieldKey] }
    };


    if (previousData[fieldKey].inputType === 'date_range') {
      previousData[fieldKey].value = {
        start: customDetails[fieldKey]?.value.start,
        end: customDetails[fieldKey]?.value.end
      };
    }

    if (updatedData[fieldKey].inputType === 'date_range') {
      updatedData[fieldKey].value = {
        start: formData[fieldKey]?.start || '',
        end: formData[fieldKey]?.end || ''
      };
    }

    console.log('Previous Data:', previousData);
    console.log('Updated Data:', updatedData);

    setEditedField(null);
    setEditMode(false);

    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };

      await axios.post(
        `${BASE_URL}/v1/asset/edit-asset-custom-details`,
        {
          asset_number: partDetails.asset_number,
          prev_obj: JSON.stringify(previousData),
          new_obj: JSON.stringify(updatedData),
        },
        { headers }
      );

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {loading ? <Loading /> : <div className="part-view-page">

        <div className="part-view-title">
          {/* <img
              src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
              alt=""
              width="24px"
              height="24px"
              onClick={handleBack}
            /> */}
          <span onClick={handleBack} style={{ cursor: 'pointer' }}> &nbsp;Asset / </span>
          <span style={{ color: "#610bee" }}> &nbsp;{partDetails.asset_number}</span>
        </div>
        <div className="part-view-cont">
          <div className="part-view-left">
            <div className="part-view-left-title">

              <div className="part-title-view">
                {!editingField.asset_title &&

                  <>
                    <span style={{ fontSize: '24px', color: '#001325', fontWeight: '500' }}>{partDetails.asset_title}</span>
                    {(shouldEdit) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }}

                      onClick={(e) => handleEditing(e, 'asset_title')} />}

                  </>}

                {editingField.asset_title && <textarea
                  value={editingValue.asset_title ? editingValue.asset_title : partDetails.asset_title}
                  onChange={(e) => handleEngineeringChange(e, 'asset_title')} />}

              </div>
              {editingField.asset_title && <div className="tckt-tmpt-btns">
                {editingValue.asset_title ? (
                  <button
                    className="tckt-tmpt-btns-save1"
                    onClick={() => handleUpdate('asset_title', editingValue.asset_title)}
                  >
                    Save
                  </button>
                ) : ''
                }

                <button
                  className="tckt-tmpt-btns-cnl"
                  onClick={(e) => handleCancelEditing(e, 'asset_title')}
                >
                  Cancel
                </button>
              </div>}


            </div>
            <div className="part-title-view">
              {!editingField.description &&

                <>
                  <span style={{ fontSize: '16px', color: '#001325', fontWeight: '500' }}>{partDetails.description}</span>

                  {(shouldEdit) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }}

                    onClick={(e) => handleEditing(e, 'description')} />}
                </>}

              {editingField.description && <textarea
                value={editingValue.description ? editingValue.description : partDetails.description}
                onChange={(e) => handleEngineeringChange(e, 'description')} />}

            </div>
            {editingField.description && <div className="tckt-tmpt-btns">
              {editingValue.description ? (
                <button
                  className="tckt-tmpt-btns-save1"
                  onClick={() => handleUpdate('description', editingValue.description)}
                >
                  Save
                </button>
              ) : ''
              }

              <button
                className="tckt-tmpt-btns-cnl"
                onClick={(e) => handleCancelEditing(e, 'description')}
              >
                Cancel
              </button>
            </div>}
            <div className="create-part-num-div-form-label-attahment">
              <span>Attachments*</span>
              <div className="filedropzone" onDrop={handleFileDrop}
                onDragOver={handleDragOver}
              >
                <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                {isLoading ? <p>Uploading ....</p> :
                  <p>Drop files to attach or<span onClick={upload}> Browse</span></p>}
              </div>
              <input
                type="file"
                id="fileupld"
                accept="image/jpeg, image/png, image/gif"
                style={{ display: "none" }}
                disabled={!shouldEdit}
                onChange={handleFileUpload}
                className="btn-upload"
              />
            </div>
            {!uploadError && <div className="error">{uploadError}</div>}
            {fileSizeError && <div className="error"><span className="error-message"
              style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {fileSizeError}</span></div>}
            <div className="uploaded-files-view" style={{ marginBottom: '25px' }}>
              {uploadedFiles.map((file, index) => (
                <div key={index} className="uploaded-file-view">
                  <div className="file-details-view" >

                    <div className="file-image-preview">
                      {getFileIcon(file.key)}
                    </div>
                    {/* <img src={PHOTO_LINK+file.key} width='80px'/> */}
                    <div className="upld-file-details-view">


                      <span title={file.name} className="file-view-name">{file.name.length > 20 ? file.name.substring(0, 15) + '...' : file.name}</span>




                    </div>

                    <div className="file-details-view-hover">
                      <a href={PHOTO_LINK + file.key} target="_blank" rel="noreferrer" >
                        <div className="file-box-link">
                          <span title={file.name} className="file-view-name-view">{file.name.length > 20 ? file.name.substring(0, 18) + '...' : file.name}</span>

                        </div>
                      </a>
                      {/* {editable ? <> */}
                      <div className="file-delete">
                        <DeleteOutlineOutlinedIcon
                          onClick={() => deleteAttachment(file.key)}
                        />
                      </div>

                      {/* </> : ''} */}
                    </div>

                  </div>




                </div>
              ))}

            </div>

          </div>

          <div className="part-view-right" style={{ width: '40%' }}>

            <div className="part-view-inputs">
              <span>Created by</span>
              <div className="part-view-owner">


                <NameProfile userName={fullName} width='28px' memberPhoto={photo} fontweight='500' />

                {fullName} (Me)
              </div>
            </div>
            {attributeOptions.map((item, index) => (
              <div key={index}>
                <div className="part-view-inputs">
                  <span>{item.title}</span>
                  <Select styles={customStyles}
                    isDisabled={!shouldEdit}
                    onChange={(selected) => handleUpdate(item.title, selected.value, 'attribute_data')}
                    //  onChange={(selected) => handlemeasureChange(selected)}
                    options={item.options.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={{ value: item.value, label: item.value }}
                  />
                </div>
              </div>

            ))}

            <div className="part-view-inputs">
              <span>Created on</span>
              <input type="date" disabled value={createdDate} />
            </div>
            {renderFields(customDetails)}
          </div>
        </div>
      </div>}

    </>

  );
}

export default AssetNumberView