import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SettingPage.css';
import { ASSET_PREFIX_URL } from '../../../constants/config';
// import Notification from '../NotificationPage/Notification';
import Privacy from '../PrivacyPage/Privacy';
import General from '../GeneralPage/General';
import UsePage from '../UsersPage/UsePage';
import CompanyPage from '../CompanyPage/CompanyPage';
// import TicketTemplateTopNav from '../../TicketComponent/TicketTemplateTopNav';

function SettingHome({ toastStyle }) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  let defaultState = 'general';
  for (let [key, value] of searchParams) {
    if (key === 'tab') {
      defaultState = value;
    }
  }
  const [selectedSection, setSelectedSection] = useState(defaultState);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    navigate(`/settings?tab=${section}`);
  };

  return (
    <>
      {/* <TicketTemplateTopNav/> */}
      <div className='settings-page'>
        <div className='settings-cont'>
          <div className='setting-home'>
            <img src={`${ASSET_PREFIX_URL}settingsIcon.svg`} alt='' width='24px' height='24px' />
            <span>Settings</span>
          </div>
          <div className='setting-navs'>
            <div
              className={`generals${selectedSection === 'general' ? ' settingActive' : ''}`}
              onClick={() => handleSectionClick('general')}
              style={{
                cursor: 'pointer',
                boxShadow: selectedSection === 'general' ? '0px 3px 0px 0px #FF7A7A' : 'none',
                padding: '10px 16px',
                color: selectedSection === 'general' ? '#FF7A7A' : 'black'
              }}
            >
              General
            </div>
            <div
              className={`generals${selectedSection === 'company' ? ' settingActive' : ''}`}
              onClick={() => handleSectionClick('company')}
              style={{
                cursor: 'pointer',
                boxShadow: selectedSection === 'company' ? '0px 3px 0px 0px #FF7A7A' : 'none',
                padding: '10px 16px',
                color: selectedSection === 'company' ? '#FF7A7A' : 'black'
              }}
            >
              Company info
            </div>
            <div
              className={`user${selectedSection === 'users' ? ' settingActive' : ''}`}
              onClick={() => handleSectionClick('users')}
              style={{
                cursor: 'pointer',
                boxShadow: selectedSection === 'users' ? '0px 3px 0px 0px #FF7A7A' : 'none',
                padding: '10px 16px',
                color: selectedSection === 'users' ? '#FF7A7A' : 'black'
              }}
            >
              Users
            </div>
            {/* <div className={`notifications${selectedSection === 'notifications' ? ' settingActive' : ''}`}
            onClick={() => handleSectionClick('notifications')}
            style={{
                cursor: 'pointer',
                boxShadow: selectedSection === 'notifications' ? '0px 3px 0px 0px #FF7A7A' : 'none',
                padding: '10px 16px',
                color: selectedSection === 'notifications' ? '#FF7A7A' : 'black'
              }}
            >
              Notification
            </div> */}
            <div
              className={`privacys${selectedSection === 'privacy' ? ' settingActive' : ''}`}
              onClick={() => handleSectionClick('privacy')}
              style={{
                cursor: 'pointer',
                boxShadow: selectedSection === 'privacy' ? '0px 3px 0px 0px #FF7A7A' : 'none',
                padding: '10px 16px',
                color: selectedSection === 'privacy' ? '#FF7A7A' : 'black'
              }}
            >
              Privacy
            </div>
          </div>
          <div className='settings-pages'>
            {selectedSection === 'general' && (
              <div className='general-settings'>
                <General />
              </div>
            )}
            {selectedSection === 'company' && (
              <div className='general-settings'>
                <CompanyPage />
              </div>
            )}
            {selectedSection === 'users' && (
              <div className='general-settings'>
                <UsePage toastStyle={toastStyle} />
              </div>
            )}
            {/* {selectedSection === 'notifications' && (
              <div className='general-settings'>
                <Notification />
              </div>
            )} */}
            {selectedSection === 'privacy' && (
              <div className='general-settings'>
                <Privacy toastStyle={toastStyle} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingHome;
