import React, { useState, useRef, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import CloseIcon from "@mui/icons-material/Close";
import './General.css'
import {
    BASE_URL,
    
    ASSET_PREFIX_URL,
  
} from "../../../constants/config";
import axios from "axios";
import Cookies from "js-cookie";

function PhotoEditPopUp({ ProfilePhoto, onClose, name }) {
    const [scale, setScale] = useState(1);
    // const [image, setImage] = useState(null);
    const [editedImage, setEditedImage] = useState(ProfilePhoto);
    // const [borderRadius, setBorderRadius] = useState(0);
    const [rotate, setRotate] = useState(0);
    const editorRef = useRef(null);

    useEffect(() => {
        setEditedImage(ProfilePhoto);
    }, [ProfilePhoto]);

    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value));
    };

    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     setImage(file);
    // };

    // const handleBorderRadiusChange = (e) => {
    //     setBorderRadius(parseFloat(e.target.value));
    // };

    const handleRotateChange = (e) => {
        setRotate(parseFloat(e.target.value));
    };

    const rotateLeft = () => {
        const newRotate = (rotate - 90 + 360) % 360; // Rotate 90 degrees to the left
        setRotate(newRotate);
    };

    const rotateRight = () => {
        const newRotate = (rotate + 90) % 360; // Rotate 90 degrees to the right
        setRotate(newRotate);
    };

    const getMenu = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", {
                headers,
            });
            const data = response.data.data;
            localStorage.setItem(
                "orgDetails",
                JSON.stringify(data.org_details.orgDetails)
            );
            localStorage.setItem(
                "memberDetails",
                JSON.stringify(data.profile_details)
            );
            localStorage.setItem(
                "starredDepartments",
                JSON.stringify(data.star_depart)
            );
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const handleSave = () => {
        if (editorRef.current) {
            try {
                const canvas = editorRef.current.getImage();
                const editedImageDataURL = canvas.toDataURL();       
                const base64Data = editedImageDataURL.split(",")[1];
                setEditedImage(base64Data);
                const profile = { photo: base64Data };
                updateProfile(profile.photo);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const updateProfile = async (photo) => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
             await axios.post(
                BASE_URL + "/v1/setting/edit-name-photo",
                { photo, fullName: name },
                { headers }
            );
            await getMenu();
            window.location.reload();
          
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="editPhoto-page" >
            <div className="editPhoto-page-div">
                <div className="edit-profile-top">
                    <span>Profile Photo Edit</span>
                    <CloseIcon onClick={onClose} className="close-icon-btn" />
                </div>
                <br />
                <div className="avtar-edit-box">
                    <AvatarEditor
                        ref={editorRef}
                        image={editedImage}
                        width={200}
                        height={200}
                        border={50}
                        scale={scale}
                        borderRadius={500}
                        rotate={rotate}
                        style={{ width: '300px', height: '300px' }}
                    />
                </div>
                <br />
                <div className="rotate-btns">
                    <button onClick={rotateLeft}><img src={`${ASSET_PREFIX_URL}left-rotate-image.svg`} alt=""/></button>
                    <button onClick={rotateRight}><img src={`${ASSET_PREFIX_URL}right-rotate-image.svg`} alt="" /></button>
                </div>
                <br />
                <div className="edit-options">
                    <div className="zoom-edit">
                        <span>zoom :</span>
                        <input
                            type="range"
                            value={scale}
                            min="1"
                            max="2"
                            step="0.01"
                            onChange={handleScaleChange}
                        />
                    </div>
                    <div className="rotate-edit">
                        <span>rotate :</span>
                        <input
                            type="range"
                            value={rotate}
                            min="0"
                            max="360"
                            step="1"
                            onChange={handleRotateChange}
                        />
                    </div>
                </div>
               
                <div className="edit-save-btn">
                    <button onClick={handleSave}>Save</button>
                </div>
                <br />
            </div>
        </div>
    );
}

export default PhotoEditPopUp;
