// RouteHandler.js
import React from "react";
import { useNavigate } from 'react-router-dom';
import { useError } from '../ErrorContext';  // Adjust path based on where App.js is located

export default function RouteHandler({ children }) {
    const navigate = useNavigate();
    const error = useError();

    React.useEffect(() => {
        if (error === '403 Forbidden') {
            navigate('/login');
        }
    }, [error, navigate]);

    return children;
}
