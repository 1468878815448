import React, { useState, useEffect } from "react";
import "./Department.css";
import axios from "axios";
import {
  BASE_URL,
  DEFAULT_PHOTO,
  PHOTO_LINK,
  ASSET_PREFIX_URL,
} from "../../constants/config";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Cookies from "js-cookie";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DepartmentTicketPopup from "./DepartmentTicketPopup";
import { useNavigate } from "react-router-dom";
import DepartmentHome from "./DepartmentHome";
import Loading from "../Common/Loading/Loading";

import NameProfile from "../Common/NameProfile/NameProfile";

function DepartmentList({ toastStyle, departmentSteps }) {
  const [hasData, setHasData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const [departmentList, setDepartmentList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const nav = useNavigate();
 
  useEffect(() => {
    fetchDepartList();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterDepartments = (departments, searchTerm) => {
    return departments.filter((department) =>
      department.departmentName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const getMenu = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", {
        headers,
      });
      const data = response.data.data;
      localStorage.setItem(
        "orgDetails",
        JSON.stringify(data.org_details.orgDetails)
      );
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(data.profile_details)
      );
      localStorage.setItem(
        "starredDepartments",
        JSON.stringify(data.star_depart)
      );
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  // const generateShortcut = (name) => {
  //   const words = name.split(" ");
  //   return words
  //     .map((word) => word[0])
  //     .join("")
  //     .toUpperCase();
  // };

  const fetchDepartList = async () => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(
        BASE_URL + "/v1/depart/get-department-list",
        { headers }
      );

      const formData = response.data.data.length;
      if (formData > 0) {
        setHasData(true);
      } else {
        setHasData(false);
      }
      setDepartmentList(response.data.data);
      setLength(response.data.data.length);
      setLoading(false);
      // setIsOpen(true)
      // setSteps(departmentSteps)
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const handleStarClick = async (item, event) => {
    event.stopPropagation();
    const name = item.departmentName;
    const departmentId = item._id;

    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      if (!item.isStarred) {
         await axios.post(
          BASE_URL + "/v1/depart/create-depart-list",
          {
            isStared: true,
            departmentID: departmentId,
            departmentName: name,
          },
          { headers }
        );
        item.isStarred = true;

        await getMenu();
        fetchDepartList();
        window.location.reload();
      } else if (item.isStarred) {
         await axios.post(
          BASE_URL + "/v1/depart/unstar-department",
          {
            department_id: departmentId,
          },
          { headers }
        );
        item.isStarred = false;

        await getMenu();
        fetchDepartList();
        window.location.reload();
        // toast.info("marked as unstared")
      }
      // window.location.reload();
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const itemsPerPage = 10;
  const handleNextPage = () => {
    if (currentPage < Math.ceil(departmentList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const filteredDepartments = filterDepartments(departmentList, searchTerm);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleGetDept = (details) => {
    // const departmentNameWithUnderscores = details.departmentName.replace(
    //   /\s+/g,
    //   "_"
    // );

    nav(`/department-ticket/${details.departmentName}/${details._id}`);
  };

  return (
    <>
      {/* <TicketTemplateTopNav/> */}
      {loading ? (
        <Loading />
      ) : (
        <>
          {hasData ? (
            <div className="depart-main" style={{ marginTop: "80px" }}>
              <div className="spn-depart">
                <span>Department</span>
                <button className="tkt-btn" onClick={handleOpenPopup}>
                  <AddOutlinedIcon />
                  New Ticket
                </button>
              </div>
              <div className="ticket-assign-page-filters-search">
                <SearchIcon style={{ color: "#001325" }} />
                <input
                  type="text"
                  placeholder="Search by department... "
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="department-table">
                <table className="department-table-data">
                  <thead>
                    <tr>
                      <th className="star-header"></th>
                       <th className="id-header">Unique initial</th> 
                      <th className="depart-header">Department ({length})</th>
                      <th className="lead-header">Lead</th>
                      {/* <th className="tck-header">Tickets</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDepartments
                      .slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                      )
                      .map((department, item) => (
                        <tr
                          key={item}
                          style={{ cursor: "pointer" }}
                          className="tr-hover"
                          onClick={() => handleGetDept(department)}
                        >
                          <td>
                            {department.isStarred ? (
                              <img
                                width="20px"
                                height="20px"
                                className="stars"
                                src={`${ASSET_PREFIX_URL}star.svg`}
                                alt="fav"
                                onClick={(event) =>
                                  handleStarClick(department, event)
                                }
                                data-tour="step-1"
                              />
                            ) : (
                              <img
                                width="20px"
                                height="20px"
                                className="stars"
                                src={`${ASSET_PREFIX_URL}unstar.svg`}
                                alt="unfav"
                                onClick={(event) =>
                                  handleStarClick(department, event)
                                }
                                data-tour="step-1"
                              />
                            )}
                          </td>
                           <td >
                            {department.unique_initial}
                          </td> 
                          <td>{department.departmentName}</td>
                          <td>
                            <div className="avt-ld">
                              
                                   <NameProfile userName={department.lead.fullName} width='26px' memberPhoto={department.lead.photo}  fontweight='500' />
                                
                            

                              {department.lead.fullName}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="prev-button"
                >
                  <KeyboardBackspaceIcon />
                  prev
                </button>
                {Array.from(
                  { length: Math.ceil(departmentList.length / itemsPerPage) },
                  (_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(index + 1)}
                      className={`pagination-button ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      {"0" + (index + 1)}
                    </button>
                  )
                )}
                <button
                  onClick={handleNextPage}
                  disabled={
                    currentPage ===
                    Math.ceil(filteredDepartments.length / itemsPerPage)
                  }
                  className="next-button"
                >
                  next
                  <KeyboardBackspaceIcon
                    style={{ transform: "rotate(180deg)" }}
                  />
                </button>
              </div>
              {/* {NewTicket && <DepartmentTicketPopup/>} */}
            </div>
          ) : (
            <DepartmentHome />
          )}

          {isPopupOpen && (
            <DepartmentTicketPopup
              onClose={handleClosePopup}
              toastStyle={toastStyle}
            />
          )}
        </>
      )}
    </>
  );
}

export default DepartmentList;
