import React, { useState } from "react";
import "./PopUp.css";
import axios from "axios";
import Select from "react-select";
import { BASE_URL } from "../../../../constants/config";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";

const options = [
  { value: "User", label: "User" },
  { value: "Admin", label: "Admin" },
];

function AddMemberPopUp({ fetchMembers }) {
  const [close, setClose] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const addMember = async () => {
    if (!email) {
      setError("Please enter an email address");
      return;
    } else if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (!selectedOption) {
      setError("Please select a role");
      return;
    }

    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const data = selectedOption.value === "Admin" ? true : false;
      const response = await axios.post(
        BASE_URL + "/v1/setting/add-mem-invite-link",
        { email, type: data },
        { headers }
      );
      console.log(response)
      if (response.data.meta.success===false) {
        setError(response.data.meta.message)
      } else {
        fetchMembers()
        setClose(true);
      }


    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setClose(true);
  };

  return (
    <div
      className="addmember-page"
      style={{ display: close ? "none" : "block" }}
    >
      <div className="addmember-container">
        <div className="add-mem-details">
          <span>Invite your team members here</span>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className="add-mem-description">
          {error && <div className="error-message">{error}</div>}
        </div>

        <div className="add-mem-select" style={{ marginTop: "25px" }}>
          <div className="add-mem-input">
            <div className="add-mem-email">
              <input
                type="email"
                placeholder="Enter Email Address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="add-mem-slct">
              <Select
                options={options}
                value={selectedOption}
                onChange={handleChange}
              />
            </div>
          </div>

          <button onClick={addMember}>Send invite</button>
        </div>
      </div>
    </div>
  );
}

export default AddMemberPopUp;
