import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';

function PoExcellSheetView({ tableViewData, setEditedTabledData, setDisableUpdate, poType, editable }) {
   
    const [rows, setRows] = useState(tableViewData);
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);

    const headers = Object.keys(tableViewData[0]);

    useEffect(() => {
        setRows(tableViewData); 
        calculateTotalAmount(tableViewData);
    }, [tableViewData]);

    const handleInputChange = (index, event) => {
       
        setDisableUpdate(true);
        const { name, value } = event.target;
        const newRows = [...rows];
        
            newRows[index][name] = {
                ...newRows[index][name], 
                value: value 
            };
        
    
        setRows(newRows);
    
        // Perform specific actions based on field name
        if (name === 'part_number') {
            fetchSuggestions(value, index);
        }
    
        if (name === 'total_cost') { // Ensure 'total_cost' matches the field name exactly as it appears in your data
            calculateTotalAmount(newRows);
        }
    };
    
    

    const fetchSuggestions = async (searchValue, rowIndex) => {
        setLoading(true);
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let response;
            if (poType === 'view') {
                response = await axios.get(`${BASE_URL}/v1/purchase-order/get-part-po`, {
                    params: { search: searchValue, page: 1, size: 10 },
                    headers
                });
             
                setSuggestions(response.data.data.parts.filter(part => !isPartNumberInTable(part.part_number)));
            } else {
                response = await axios.get(`${BASE_URL}/v1/asset/get-asset-in-asset-po`, {
                    params: { search: searchValue, page: 1, size: 10 },
                    headers
                });
                setSuggestions(response.data.data.asset.filter(asset => !isPartNumberInTable(asset.asset_number)));
            }

            setActiveSuggestionIndex(rowIndex);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    const isPartNumberInTable = (partNumber) => {
        return rows.some(row => row.part_number.value === partNumber);
    };

    const handleSuggestionClick = (rowIndex, suggestion) => {

        const newRows = [...rows];

        // Update the clicked row with the suggestion's value


        // Update part_number and description fields based on poType
        if (poType === 'view') {
            newRows[rowIndex].part_number = {
                ...newRows[rowIndex].part_number,
                value: suggestion.part_number,
            };
            newRows[rowIndex].description = {
                ...newRows[rowIndex].description,
                value: suggestion.part_title,
            };
        } else {
            newRows[rowIndex].description = {
                ...newRows[rowIndex].description,
                value: suggestion.asset_title,
            };
            newRows[rowIndex].part_number = {
                ...newRows[rowIndex].part_number,
                value: suggestion.asset_number,
            };
        }
      
        setRows(newRows);
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
    };



    const handleAddRow = () => {
        // Create a new row object with default values from the first object in tableViewData
        const newRow = {};

        // Initialize newRow with values from the first object in tableViewData
        Object.keys(tableViewData[0]).forEach(header => {
            newRow[header] = {
                value: "",
                _id: tableViewData[0][header]._id ,
                fieldName: tableViewData[0][header].fieldName ,
                template_id: tableViewData[0][header].template_id ,
                organization_id: tableViewData[0][header].organization_id ,
                inputType: tableViewData[0][header].inputType ,
                is_default: tableViewData[0][header].is_default ,
                template_type : tableViewData[0][header].template_type,
                is_default_template: tableViewData[0][header].is_default_template ,
                order : tableViewData[0][header].order,
                // Add other properties as needed
            };
        });

        // Set the "sr.no" field to the next sequential number
        newRow["sr.no"].value = rows.length + 1;

        // Add the new row to the existing rows
        const updatedRows = [...rows, newRow];

     
        setRows(updatedRows);
    };





    const handleDeleteRow = (index) => {
        if (rows.length > 1) {
            const newRows = rows.filter((_, i) => i !== index);
            setRows(newRows);
            calculateTotalAmount(newRows);
        }
    };

    useEffect(() => {
        if (JSON.stringify(rows) === JSON.stringify(tableViewData)) {
            setEditedTabledData(tableViewData); // Reset to original data if no changes
        } else {
            setEditedTabledData(rows); // Update edited data when changes detected
        }
    }, [rows, tableViewData, setEditedTabledData]);

    const calculateTotalAmount = (rowsData) => {
        const total = rowsData.reduce((sum, row) => {
            const totalCost = parseFloat(row['Total cost']) || 0;
            return sum + totalCost;
        }, 0);
        setTotalAmount(total);
    };

    return (
        <div className="po-excel-sheet-container">
            <span style={{ fontWeight: '600' }}>Item table</span>
            <table className="po-table" style={styles.poTable}>
                <thead>
                    <tr>
                        {/* {headers.map((header, index) => (
                            <th key={index} style={styles.tableHeader}>
                                {header}
                            </th>

                        ))} */}
                        {headers.map((header, index) => (
                            <th key={index} style={styles.tableHeader}>{tableViewData[0][header].fieldName}</th>
                        ))}

                        <th style={styles.tableHeader}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((header, index) => (
                                <td key={index} style={styles.tableCell}>
                                    <input
                                        type="text"
                                        disabled={!editable}
                                        name={header}
                                        value={row[header].value}
                                        onChange={(event) => handleInputChange(rowIndex, event)}
                                        style={styles.input}
                                    />
                                    {header === 'part_number' && rowIndex === activeSuggestionIndex && suggestions.length > 0 && (
                                        <div className="suggestions-container">
                                            <div className="suggestions-box" style={styles.suggestionsBox}>
                                                {loading ? (
                                                    <div className='loader-po-div'>
                                                        <div className="loader-po"></div>
                                                    </div>
                                                ) : (
                                                    suggestions.length < 1 ? (
                                                        <div className="suggestion-item" style={styles.suggestionItem}>No parts!</div>
                                                    ) : (
                                                        suggestions.map((suggestion, index) => (
                                                            <div
                                                                key={index}
                                                                className="suggestion-item"
                                                                style={styles.suggestionItem}
                                                                onClick={() => handleSuggestionClick(rowIndex, suggestion)}
                                                            >
                                                                {poType === 'view' ? (
                                                                    <>
                                                                        {suggestion.part_number}, {suggestion.part_title}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {suggestion.asset_number}, {suggestion.asset_title}
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </td>
                            ))}
                            {editable && <td style={styles.tableCell}>
                                <img
                                    style={styles.icon}
                                    src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                    alt='delete'
                                    onClick={() => handleDeleteRow(rowIndex)}
                                />
                            </td>}

                        </tr>
                    ))}
                </tbody>
            </table>
            {editable && <div className="spreadsheet-controls" style={{ marginTop: '10px' }}>
                <img
                    src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                    alt='add'
                    width='24px'
                    height='24px'
                    style={{ cursor: 'pointer' }}
                    onClick={handleAddRow}
                />
            </div>}

        </div>
    );
}

const styles = {
    poTable: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        border: '1px solid #ddd',
    },
    tableHeader: {
        padding: '8px',
        border: '1px solid #ddd',
        backgroundColor: '#f0f0f0',
        textAlign: 'left',
    },
    tableCell: {
        padding: '8px',
        border: '1px solid #ddd',
        position: 'relative',
        textAlign: 'left',
    },
    input: {
        width: '90%',
        padding: '8px',
        border: '1px solid #edf2f7',
        borderRadius: '4px',
        background: 'white',
    },
    icon: {
        cursor: 'pointer',
    },
    suggestionsBox: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        border: '1px solid #ddd',
        zIndex: 1000,
        maxHeight: '150px',
        overflowY: 'auto',
    },
    suggestionItem: {
        padding: '8px',
        cursor: 'pointer',
        borderBottom: '1px solid #ddd',
    },
};

export default PoExcellSheetView;
