import React, { useEffect, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './TicketView.css'
import { DEFAULT_PHOTO, PHOTO_LINK, ASSET_PREFIX_URL } from "../../constants/config";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import NameProfile from '../Common/NameProfile/NameProfile';

function TicketChangeLogs({ onClose, ticketId, type }) {
    console.log(type)
    const [changeLogData, setChangeLogData] = useState([]);
    useEffect(() => {
        fetchChangeLogs();
    }, []);

    const fetchChangeLogs = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let url;
            if (type === 'template') {
                url = `${BASE_URL}/v1/ticket/get-change-logs/${ticketId}`;
            }
            if(type === 'view'){
                url =  url = `${BASE_URL}/v1/purchase-order/get-po-activity-logs`;
            }
            if(type === 'assets-view'){
                url =  url = `${BASE_URL}/v1/asset/get-asset-activity-logs`;
            }
            if (type === 'ec-template') {

                url = `${BASE_URL}/v1/ec-activity/get-change-logs`;
            }
            if (type === 'ec-deviation') {

                url = `${BASE_URL}/v1/ec-activity/get-deviation-logs`;
            }

let response;
           if(type === 'template'){
             response = await axios.get(
                url,
                { headers }
            );
           } else if(type === 'view'){
            response = await axios.get(
                url,
                {
                    params: { po_name: ticketId },
                    // params: params,
                    headers: headers,
                  }
            );
           }else if(type === 'assets-view'){
            response = await axios.get(
                url,
                {
                    params: { asset_name: ticketId },
                    // params: params,
                    headers: headers,
                  }
            );
           }
            else{
                 response = await axios.get(
                    url,
                    {
                        params: { [type === 'ec-template' ? 'ec_id' : 'dev_id']: ticketId },
                        // params: params,
                        headers: headers,
                      }
                );
                
            }
            const activityLogs = response.data.data.activityLogs || [];
            console.log(response)
            setChangeLogData(activityLogs);
            console.log(response)
        } catch (error) {
            console.log(error);
        }
    };

    const categorizeChangeLogs = (logs) => {
        const categorizedLogs = {};
        logs.forEach((log) => {
            const logDate = new Date(log.updatedAt);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            let category;
            if (logDate.toDateString() === today.toDateString()) {
                category = "Today";
            } else if (logDate.toDateString() === yesterday.toDateString()) {
                category = "Yesterday";
            } else {
                category = logDate.toDateString();
            }

            if (!categorizedLogs[category]) {
                categorizedLogs[category] = [];
            }
            categorizedLogs[category].push(log);
        });

        // Reverse the order of categories
        const reversedCategories = {};
        Object.keys(categorizedLogs).reverse().forEach((category) => {
            reversedCategories[category] = categorizedLogs[category].reverse();
        });

        return reversedCategories;
    };

    const categorizedLogs = categorizeChangeLogs(changeLogData);


    return (
        <div className="changelog-page">
            <div className="changelog-container">
                <div className="changelog-container-head">
                    <span>Change logs</span>
                    <div>
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
                    </div>
                </div>
                {Object.keys(categorizedLogs).map((category) => (
                    <div className="changelogs-status" key={category}>
                        <span className="changelogs-day">{category}</span>
                        {categorizedLogs[category].map((log) => (
                            <div className="changelogs-details" key={log._id}>
                                <div className='changelogs-heads'>
                                    <div className='changeLong-dot'></div>
                                    {Object.keys(log.newObj).map((key) => (
                                        <span className='changelogs-fieldname'>{log.newObj[key].fieldName === 'Ticket Title' ? 'Title' : log.newObj[key].fieldName}</span>
                                    ))}

                                    <span className='changelogs-time'>{new Date(log.updatedAt).toLocaleString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                    })}</span>
                                </div>
                                <div className='changelogs-prev'>
                                    {/* Render previous and new values */}


                                    {Object.keys(log.prevObj).map((key) => {
                                        if (key === 'priority') {
                                            if (log.prevObj[key].value === 'P0')
                                                return (
                                                    <div className='parag-changes'>

                                                        <span key={key} style={{
                                                            background: '#DA2370', paddingLeft: "15px",
                                                            paddingRight: "15px",
                                                        }}
                                                            className="security-icon">{log.prevObj[key].value}</span>
                                                    </div>

                                                );
                                            if (log.prevObj[key].value === 'P1')
                                                return (
                                                    <div className='parag-changes'> <span key={key} style={{
                                                        background: '#EE962F', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.prevObj[key].value}</span></div>

                                                );
                                            if (log.prevObj[key].value === 'P2')
                                                return (
                                                    <div className='parag-changes'><span key={key} style={{
                                                        background: '#00B884', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.prevObj[key].value}</span></div>
                                                );
                                            if (log.prevObj[key].value === 'P3')
                                                return (
                                                    <div className='parag-changes'>  <span key={key} style={{
                                                        background: '#5696F2',
                                                        paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.prevObj[key].value}</span></div>
                                                );
                                            if (log.prevObj[key].value === 'P4')
                                                return (
                                                    <div className='parag-changes'>
                                                        <span key={key} style={{
                                                            background: '#5696F2',
                                                            paddingLeft: "15px",
                                                            paddingRight: "15px",
                                                        }}
                                                            className="security-icon">{log.prevObj[key].value}</span>
                                                    </div>

                                                );
                                        } else if (key === 'security') {
                                            if (log.prevObj[key].value === 'S0')
                                                return (
                                                    <div className='parag-changes'> <span key={key} style={{
                                                        background: '#EE962F',
                                                        paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.prevObj[key].value}</span></div>
                                                );

                                            if (log.prevObj[key].value === 'S1')
                                                return (
                                                    <div className='parag-changes'> <span key={key} style={{
                                                        background: '#EE962F',
                                                        paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.prevObj[key].value}</span></div>
                                                );
                                            if (log.prevObj[key].value === 'S2')
                                                return (
                                                    <div className='parag-changes'>  <span key={key} style={{
                                                        background: '#00B884',
                                                        paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.prevObj[key].value}</span></div>

                                                );
                                            if (log.prevObj[key].value === 'S3')
                                                return (
                                                    <div className='parag-changes'>
                                                        <span key={key} style={{
                                                            background: '#5696F2',
                                                            paddingLeft: "15px",
                                                            paddingRight: "15px",
                                                        }}
                                                            className="security-icon">{log.prevObj[key].value}</span>
                                                    </div>
                                                );
                                            if (log.prevObj[key].value === 'S4')
                                                return (
                                                    <div className='parag-changes'> <span key={key} style={{
                                                        background: '#5696F2',
                                                        paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.prevObj[key].value}</span></div>

                                                );
                                        } else if (log.prevObj[key].inputType === 'date') {
                                            return (
                                                <span key={key} className='parag-changes'>{log.prevObj[key].value}</span>
                                            )
                                        } else if (log.prevObj[key].inputType === 'date_range') {

                                            return (
                                                <div style={{ display: 'flex', flexDirection: "column", marginRight: "15px" }} className='parag-changes'>
                                                    start: <span key={key}>{log.prevObj[key].value.start}</span>
                                                    end: <span key={key}>{log.prevObj[key].value.end}</span>
                                                </div>

                                            );
                                        }
                                        //  else if (key === 'description') {
                                        //     return (
                                        //         <span style={{ width: '10vw', display: 'block', whiteSpace: 'normal', wordBreak: 'break-all' }}>{log.prevObj[key].value}</span>
                                        //     )
                                        // }
                                        else if (log.prevObj[key].inputType === 'text' || key === 'description' || key === 'ticket_title' ||
                                            log.prevObj[key].inputType === 'link' || log.prevObj[key].inputType === 'number'||log.prevObj[key].inputType=== 'select-person'
                                            || log.prevObj[key].inputType === 'paragraph' || log.prevObj[key].inputType === 'single_select') {
                                            return (

                                                <span style={{ display: 'block', whiteSpace: 'normal', }} className='parag-changes'>{log.prevObj[key].value}</span>


                                            )
                                        } else if (log.prevObj[key].fieldName === 'Attachment') {
                                            return (
                                                <>

                                                    <span style={{ display: 'block', whiteSpace: 'normal', wordBreak: 'break-all' }} className='parag-changes'>
                                                        {log.newObj[key].value.length > 0 ? log.newObj[key].value[log.newObj[key].value.length - 1].action : ''}</span>
                                                </>

                                            )
                                        } else if (log.prevObj[key].inputType === 'multi_select' || log.prevObj[key].fieldName === 'Watchers' ) {

                                            return (

                                                < >
                                                    {(log.newObj[key].fieldName === 'Watchers' ) ?
                                                        <div className='members-photo-details parag-changes-watchers' >

                                                            {log.prevObj[key].value.map(item => (
                                                                <div key={item.id} className='members-photo-details ' >
                                                                    <div className='member-item' >

                                                                        <div className="owner-image-changes" key={item.id}>
                                                                            <NameProfile userName={item.fullName} memberPhoto={item.photo} width="26px" fontweight='500' />
                                                                        </div>


                                                                    </div>

                                                                    {/* <span className='change-details-newData'>{item.fullName}</span> */}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className='parag-changes'>
                                                            <span className='change-details-newData'>
                                                                {log.prevObj[key].fieldName === 'Select EC' ? log.prevObj[key].value.map(item => item.ec_title).join(', ') : log.prevObj[key].value.join(', ')}

                                                            </span>
                                                        </div>}



                                                </>
                                            )
                                        } else {
                                            return (
                                                <div key={key}>
                                                    {key === 'assignee' || key === 'owner' || log.prevObj[key].inputType === 'person' ? (
                                                        <div className='user-info parag-changes' title={log.prevObj[key].value.email}>
                                                            <NameProfile
                                                                userName={log.prevObj[key].value.fullName} memberPhoto={log.prevObj[key].value.photo} width="26px" fontweight='500' />

                                                            &nbsp;

                                                            <span>{log.prevObj[key].value.fullName}</span>
                                                        </div>
                                                    ) : (
                                                        <span>no defined</span>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                                    <img src={`${ASSET_PREFIX_URL}changes-arrow.svg`} alt='' width='12px' style={{ marginLeft: '30px' }} className='arrow-watchers' />
                                    {Object.keys(log.newObj).map((key) => {
                                        if (key === 'priority') {
                                            if (log.newObj[key].value === 'P0')
                                                return (
                                                    <span key={key} style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        paddingLeft: "15px",
                                                        paddingRight: "15px", background: '#DA2370'
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'P1')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#EE962F', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'P2')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#00B884', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'P3')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#5696F2', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'P4')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#5696F2', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                        } else if (key === 'security') {
                                            if (log.newObj[key].value === 'S0')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#EE962F', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'S1')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#EE962F', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'S2')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#00B884', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'S3')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#5696F2', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                            if (log.newObj[key].value === 'S4')
                                                return (
                                                    <span key={key} style={{
                                                        background: '#5696F2', paddingLeft: "15px",
                                                        paddingRight: "15px",
                                                    }}
                                                        className="security-icon">{log.newObj[key].value}</span>
                                                );
                                        } else if (key === 'ticket_title') {
                                            return (
                                                <span key={key} style={{ display: 'block', whiteSpace: 'normal', wordBreak: 'break-all' }} className='parag-changes'>{log.newObj[key].value}</span>
                                            );
                                        } else if (key === 'description' || log.newObj[key].inputType === 'text' ||
                                            log.newObj[key].inputType === 'paragraph' || log.newObj[key].inputType === 'link' ||
                                            log.newObj[key].inputType === 'number' || log.newObj[key].inputType === 'date' || log.newObj[key].inputType === 'single_select'||
                                            log.prevObj[key].inputType=== 'select-person') {
                                            return (
                                                <span style={{ display: 'block', whiteSpace: 'normal', wordBreak: 'break-all' }} className='parag-changes'>
                                                    {log.newObj[key].value}</span>
                                            )
                                        } else if (log.newObj[key].inputType === 'multi_select' || log.newObj[key].fieldName === 'Watchers') {
                                            return (
                                                <>
                                                    {(log.newObj[key].fieldName === 'Watchers') ?
                                                        <>

                                                            {log.newObj[key].value.map(item => (
                                                                <div key={item.id} className='members-photo-details'>
                                                                    <div className='member-item'>


                                                                        <NameProfile userName={item.fullName} memberPhoto={item.photo} width="26px" fontweight='500' />

                                                                    </div>

                                                                    {/* <span className='change-details-newData'>{item.fullName}</span> */}
                                                                </div>
                                                            ))}
                                                        </>
                                                        :
                                                        <>
                                                            {/* {log.newObj[key].value.map(item => ( */}

                                                            <div className='parag-changes'>

                                                                <span className='change-details-newData'>
                                                                    {log.newObj[key].fieldName === 'Select EC' ? log.newObj[key].value.map(item => item.ec_title).join(', ') : log.newObj[key].value.join(', ')}


                                                                </span>
                                                            </div>



                                                            {/* ))} */}
                                                        </>
                                                    }


                                                </>
                                            )
                                        } else if (log.newObj[key].inputType === 'date_range') {

                                            return (
                                                <div style={{ display: 'flex', flexDirection: "column", marginLeft: "15px" }}>
                                                    start:<span key={key}>{log.newObj[key].value.start}</span>
                                                    end:<span key={key}>{log.newObj[key].value.end}</span>
                                                </div>

                                            );
                                        } else if (log.newObj[key].inputType === 'attachment') {
                                            return (
                                                <span style={{ display: 'block', whiteSpace: 'normal', wordBreak: 'break-all' }} className='parag-changes'>{log.newObj[key].value.length > 0 ? log.newObj[key].value[log.newObj[key].value.length - 1].name : ''}</span>
                                            )
                                        } else {
                                            return (
                                                <div key={key}>
                                                    {key === 'assignee' || key === 'owner' || log.newObj[key].inputType === 'person' ? (
                                                        <div className='user-info' style={{ margin: '5px' }}>

                                                            <NameProfile userName={log.newObj[key].value.fullName} memberPhoto={log.newObj[key].value.photo} width="26px" fontweight='500' />

                                                            &nbsp;
                                                            <span >{log.newObj[key].value.fullName}</span>
                                                        </div>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}

                                </div>
                                <div className='changelogs-by'>
                                    <span>by {log.updatedBy.name}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TicketChangeLogs;
