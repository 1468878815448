import React from 'react'
import './MemberSettings.css'
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import {  PHOTO_LINK,ASSET_PREFIX_URL } from "../../../constants/config";
import Cookies from "js-cookie";
import NameProfile from '../NameProfile/NameProfile';

function MemberSettings({memberPhoto, memberName,memberEmail,onClose}) {
  const nav=useNavigate()
  const HandleSetting=()=>{
    onClose()
    nav('/settings')
  }
  const logout = () => {
    Cookies.remove('token');
    localStorage.clear(); 
     nav("/login");
    //  window.location.reload();
   }
  return (
    <div className='member-settings-page-div' >
      <div className='cross-icon'>
        <div className='cross-hover-effect'>
        <CloseIcon onClick={onClose} />
        </div>
     
      </div>
     <br/>
     <br/>
      <div className='member-setting-details'>
      <div>Hi, <a className='member-name-span'>{memberName}</a></div>
       
      <NameProfile userName={memberName} width='100px' memberPhoto={memberPhoto} fontSize='32px' fontweight='500' />

        {/* <img src={memberPhoto?PHOTO_LINK+memberPhoto:DEFAULT_PHOTO} alt='' /> */}
       
        <span>{memberEmail}</span>
        <button title='settings' className='member-settings-btn' onClick={HandleSetting}><img src={`${ASSET_PREFIX_URL}settings-image.png`}
          style={{width:'25px', height:'25px',borderRadius:'50%'}}
         />&nbsp;&nbsp;&nbsp; Manage your Settings</button> <br/>
         <button  title='logout' className='member-settings-btn ' onClick={logout}>  <img src={`${ASSET_PREFIX_URL}logout-signout.png`}
         style={{width:'20px', height:'20px'}}
         /> &nbsp;&nbsp;&nbsp; logout</button>
      </div>
     
    </div>
  )
}

export default MemberSettings