import React, { useEffect, useState, useRef } from 'react';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import axios from 'axios';
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";

import Loading from '../Common/Loading/Loading';



function EcPartsForm({ typeEC, handlePrevStepToParts, handleCreate, selectedPartNumbers, errorMessage }) {
    const [partOptions, setPartOptions] = useState([]);
    
    // const [selectedParts, setSelectedParts] = useState(typeEC === 'part-variation' ? selectedPartNumbers : []);
    const [selectedParts, setSelectedParts] = useState([])
    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [fetching, setFetching] = useState(false);
    const LIMIT = 10;
    const containerRef = useRef(null);
   
 


    useEffect(() => {
        
        setPartOptions([]);
        // setFetching(true)
    }, [search]);

    const handleSearch = (e) => {
        let value = e.target.value
        setSearch(value)
        setActivePage(1);

    }
    useEffect(() => {
        if (search !== '') {
            const delayTimer = setTimeout(() => {
                getParts();

                // setActivePage(prevPage => prevPage + 1);
            }, 2000); // Adjust the debounce delay (in milliseconds) as needed

            return () => clearTimeout(delayTimer);
        } else {
            getParts();
        }
    }, [activePage, search]); // Update parts when the active page or search query changes


    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const handleScroll = debounce(() => {
        const container = containerRef.current;
        if (!hasMore || fetching) {
           
            return;
        }
    
       
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
            setActivePage(prevPage => prevPage + 1);
        }
    }, 500); 

    useEffect(() => {
        const container = containerRef.current;
    
        if (container) {
            container.addEventListener('scroll', handleScroll);
    
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [containerRef, handleScroll]);

    const getParts = async () => {
        try {
            setFetching(true);
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            let response;
            if (typeEC === 'ec-template' || typeEC === 'ec-deviation') {
                response = await axios.get(
                    `${BASE_URL}/v1/ec-deviations/unique-parts`,
                    {
                        params: { search: search, page: activePage, size: LIMIT },
                        headers: headers,
                    } 
                );
            } else if (typeEC === 'part-create' || typeEC === 'part-variation') {
                response = await axios.get(
                    `${BASE_URL}/v1/part-nomen/get-list-part-number`,
                    {
                        params: { search: search, page: activePage, limit: LIMIT },
                        // params: search ? { search: search } : { page: activePage, size: LIMIT },
                        headers: headers,
                    }
                );
            }

            const { part_number_list,
                //  total_count, count_part_list
                 } = response.data.data;
            // if (search   ) {
            //     // If it's the first page of search results, replace partOptions with the new search results
            //     setPartOptions(part_number_list);

            // } else {
            //     // If it's not the first page of search results or if there's no search query, append to partOptions
            //     setPartOptions(prevOptions => [...prevOptions, ...part_number_list]);
            // }
            setPartOptions((prev) => {
                return [
                    ...new Set([...prev, ...part_number_list]),
                ];
            });
            // setOptionsCount(total_count);
            setHasMore(part_number_list.length === LIMIT);
            // setLoading(false);
            console.log(response)
            setFetching(false);
        } catch (error) {
            console.error("Error fetching parts:", error);
            setFetching(false);
        }
    };

    const toggleSelectPart = (part) => {
        console.log(part)
        if ((typeEC !== 'ec-template' && typeEC !== 'ec-deviation') && !part.quantity) {
            setError('Quantity needs to have more than 1');
        } else {
            setError('')
            if (typeEC === 'ec-template' || typeEC === 'ec-deviation') {
                console.log('ec')
                const index = selectedParts.findIndex((selectedPart) => selectedPart.part_number === part.part_number);
                if (index === -1) {
                    const { _id, ...partWithoutId } = part;
                    setSelectedParts([...selectedParts, { ...partWithoutId, part_id: part._id }]);
                    // setSelectedParts([...selectedParts, { ...part, part_id: part._id }]);
                } else {
                    const updatedSelectedParts = [...selectedParts];
                    updatedSelectedParts.splice(index, 1);
                    setSelectedParts(updatedSelectedParts);
                }
            }else if (typeEC === 'part-create') {
                console.log('creation')
                const { part_number, part_title, quantity } = part;
                const index = selectedParts.findIndex(selectedPart => selectedPart.part_number === part_number);
            
                if (index === -1) {
                    setSelectedParts([...selectedParts, { part_number, part_title, quantity }]);
                } else {
                    const updatedSelectedParts = [...selectedParts];
                    updatedSelectedParts.splice(index, 1);
                    setSelectedParts(updatedSelectedParts);
                }
            }
             else {
                console.log('variation')
                const { part_number, part_title, quantity } = part;
                const index = selectedParts.findIndex((selectedPart) => selectedPart.part_number === part.part_number);
                console.log(index)
                if (index === -1) {
                    setSelectedParts([...selectedParts, { part_number, part_title, quantity }]);
                    console.log('jfgdf') // Pushing the entire part object
                } else {
                    const updatedSelectedParts = [...selectedParts];
                    updatedSelectedParts.splice(index, 1);
                    setSelectedParts(updatedSelectedParts);
                    console.log('fdgdfdbdf')
                }
            }
        }


    };

    const createEC = (event) => {
      
       
        if (typeEC === 'ec-template' || typeEC === 'ec-deviation') {
            const selectedPartsStringified = JSON.stringify(selectedParts);
            console.log("Selected Parts:", selectedPartsStringified);
            handleCreate(event, typeEC, selectedPartsStringified);
        } else {
            if (errorMessage) {
                setError(errorMessage);
            } else {
                handleCreate(selectedParts);
            }
        }
        // console.log(selectedParts)

    };

    const handleQuantityChange = (e, item) => {
        const newQuantity = e.target.value.trim();
        if (newQuantity === '' || /^\d+$/.test(newQuantity)) {
            const updatedQuantity = newQuantity === '' ? '' : parseInt(newQuantity);
            setPartOptions(prevPartOptions => {
                return prevPartOptions.map(part => {
                    if (part.part_number === item.part_number) {
                        return { ...part, quantity: updatedQuantity };
                    }
                    return part;
                });
            });
            console.log("Updated item:", { ...item, quantity: updatedQuantity });
        }
    };

    return (
        <div className="ec-part-page" >
            <div className="ec-ticket-home">
                <div className="ex-ticket-title">
                    <span className="ec-title">
                        {typeEC === 'ec-template' && 'Create new EC'}
                        {typeEC === 'ec-deviation' && 'Create new deviation'}
                        {typeEC === 'part-create' && 'Add Child Part(s)'}
                        {typeEC === 'part-variation' && 'Add Child Part(s)'}
                    </span>
                    <PopupCloseButton handleClose={handlePrevStepToParts} />
                </div>

                <div className="dept-ticket-steps">
                    <span>step 2 of 2</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}ticket-step2.svg`} alt="" width="100%" />
                <div className="ticket-assign-page-filters-search">
                    <SearchIcon style={{ color: "#001325" }} />
                    <input type="text" placeholder="Search part number..." value={search} onChange={(e) => handleSearch(e)} />
                </div>
                {/* {(typeEC === 'part-variation' && errorMessage)&&  <span className='error-message'>{error}</span>} */}
                {error !== '' && <span className='error-message'>{error}</span>}
                <div className='part-div-infinite' onScroll={handleScroll} ref={containerRef}>
                 <table className="department-table-part-data">
                        <thead>
                            <tr>
                                <th className='part-num' >Part number</th>
                                <th className='part-tit'>Part title</th>
                                {(typeEC === 'part-create' || typeEC === 'part-variation')&&<th className='part-quan'>Quantity</th>}
                                <th></th>
                            </tr>
                        </thead>
                        {/* {partOptions.length<1 ? <div className='data-not-found-img'>
    <img src={`${ASSET_PREFIX_URL}no-data-found.png`} />
    </div>: */}
                        <tbody>
                            {partOptions.map((item) => (
                                <tr className="tr-hover" key={item._id}>
                                    <td>{item.part_number}</td>
                                    <td>{item.part_title}</td>
                                    {(typeEC === 'part-create' || typeEC === 'part-variation')&&  <td>
                                        <input type="number" 
                                            placeholder={!item.quantity  && '+Qty'} value={item.quantity}
                                            className='part-numbers-views-input' onChange={(e) => handleQuantityChange(e, item)} />
                                    </td>}
                                   
                                    {(typeEC === 'ec-template' || typeEC === 'ec-deviation') &&
                                        <td>
                                            {selectedParts.some((selectedPart) => selectedPart.part_number === item.part_number) ?
                                                <img
                                                    width='30px' height='30px'
                                                    src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                                    alt='delete'
                                                    onClick={() => toggleSelectPart(item)}
                                                /> :
                                                <img
                                                    width='30px' height='30px'
                                                    src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                                                    alt='delete'
                                                    onClick={() => toggleSelectPart(item)}
                                                />}
                                        </td>}
                                    {(typeEC === 'part-create'||typeEC === 'part-variation') &&
                                        <td>
                                            {selectedParts.some((selectedPart) => selectedPart.part_number === item.part_number) ?
                                                <img
                                                    width='30px' height='30px'
                                                    src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                                    alt='delete'
                                                    onClick={() => toggleSelectPart(item)}
                                                /> :
                                                <img
                                                    width='30px' height='30px'
                                                    src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                                                    alt='delete'
                                                    onClick={() => toggleSelectPart(item)}
                                                />}
                                        </td>}
                                    {/* {(typeEC === 'part-variation') &&
                                        <td>
                                            {selectedParts.some((selectedPart) => selectedPart.part_number === item.part_number) ?
                                                <img
                                                    width='30px' height='30px'
                                                    src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                                    alt='delete'
                                                    onClick={() => toggleSelectPart(item)}
                                                /> :
                                                <img
                                                    width='30px' height='30px'
                                                    src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                                                    alt='delete'
                                                    onClick={() => toggleSelectPart(item)}
                                                />}
                                        </td>} */}
                                </tr>
                            ))}
                            {fetching && <tr><td colSpan="4"><Loading /></td></tr>}
                        </tbody>


                    </table>
                  
                </div>

                <div className="depart-form-btns">
                    <button className="dept-form-cancel" onClick={handlePrevStepToParts}>
                        Go back
                    </button>
                    <button className="dept-form-submit" onClick={(event) => createEC(event)}>
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EcPartsForm;
