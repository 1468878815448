import React,{useState} from 'react'
import { ASSET_PREFIX_URL,PHOTO_LINK } from '../../../constants/config';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function AttachmentsFunction({uploadedFiles,setUploadedFiles,type}) {

  const [fileLoadingStates, setFileLoadingStates] = useState([]);
  const getFileIcon = (fileName) => {
    console.log(fileName);
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div
          style={{ overflow: "hidden", border: "none" }}
          className="extension-type-div"
        >
          <iframe
            src={PHOTO_LINK + fileName}
            title="CSV Preview"
            //   width= '75px'
            // height= '75px'
            // border= 'none'
            // style={{ overflowY: 'hidden', border: 'none',padding:'0px' }}
            // scrolling="no"
          />
        </div>
      );
    } else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xlsFile.png`}
          alt="Excel"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "xml") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xmlFile.png`}
          alt="XML"
          width="75px"
          height="75px"
        />
      );
    } else if (
      extension === "jpg" ||
      extension === "png" ||
      extension === "jpeg" ||
      extension === "svg"
    ) {
      return <img src={PHOTO_LINK + fileName} alt="" className="png-img-div" />;
    } else if (extension === "pptx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}pptxFile.png`}
          alt="ppt"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "csv") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}csvFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "docx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}wordFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else {
      return (
        <img
          src={`${ASSET_PREFIX_URL}filesImage.png`}
          alt="File"
          width="75px"
          height="75px"
        />
      );
    }
  };

  const handleDelete = (index, uploadedFiles, fileLoadingStates, setUploadedFiles, setFileLoadingStates) => {
    const newFiles = [...uploadedFiles];
    const newLoadingStates = [...fileLoadingStates];
    newFiles.splice(index, 1);
    newLoadingStates.splice(index, 1);
    setUploadedFiles(newFiles);
    setFileLoadingStates(newLoadingStates);
  };
  return (
    <div className="uploaded-files-view">
    {uploadedFiles.map((file, index) => (
      <div key={index} className="uploaded-file-view">
        <div className="file-details-view">
          <div className="file-image-preview">
            {getFileIcon(file.key)}
          </div>

          
          <div className="upld-file-details-view">
            <span title={file.name} className="file-view-name">
              {file.name.length > 20
                ? file.name.substring(0, 15) + "..."
                : file.name}
            </span>
          </div>

          <div className="file-details-view-hover">
            <a href={PHOTO_LINK + file.key} target="_blank">
              <div className="file-box-link">
                <span
                  title={file.name}
                  className="file-view-name-view"
                >
                  {file.name.length > 20
                    ? file.name.substring(0, 18) + "..."
                    : file.name}
                </span>
              </div>
            </a>

            <div className="file-delete">
              <DeleteOutlineOutlinedIcon
               onClick={() => handleDelete(index, uploadedFiles, fileLoadingStates, setUploadedFiles, setFileLoadingStates)} 
              />
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
  )
}

export default AttachmentsFunction