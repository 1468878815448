import React, { useState } from "react";
import "./Edit.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { BASE_URL,  ASSET_PREFIX_URL } from "../../../constants/config";
import Cookies from "js-cookie";

function EditRole({ activeNode, setAction }) {
    const [close, setClose] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const handleClose = () => {
        setClose(true);
        setAction(false)
    };

    const handleAddMember = async () => {
        // Reset form submission status and validation errors
        setFormSubmitted(true);
        setValidationErrors({});

        // Validate inputs
        if (!jobTitle.trim()) {
            setValidationErrors(prevErrors => ({ ...prevErrors, jobTitle: "Job Title is required." }));
            return;
        }

        try {
            await axios.put(BASE_URL + "/v1/org/edit-role", {
                  entity_id: activeNode.entity_id,
                  jobTitle,
                },
                {
                  headers: {
                    "x-auth-token": Cookies.get('token'),
                  }
                });
            window.location.reload();

        } catch (error) {
            console.error(error.message);
           
        }
    };
    return (
        <div className="editRole" style={{ display: close ? "none" : "block" }}>
            <div className="upper-part">
                <div className="cancel">
                    <span>Edit role for<span className="active-name"> {activeNode.fullName}</span></span>
                    <CloseIcon onClick={handleClose} />
                </div>

                <div className="editJob">
                    <span>New job title</span>
                    <input
                        type="text"
                        placeholder="Enter Title"
                        onChange={(e) => setJobTitle(e.target.value)}
                        value={jobTitle}
                    />
                    {/* Display validation error for jobTitle */}
                    {formSubmitted && validationErrors.jobTitle && (
                        <div className="department-error"><img src={`${ASSET_PREFIX_URL}warning.svg`} alt=""/>&nbsp;&nbsp;&nbsp;{validationErrors.jobTitle}</div>
                    )}
                </div>
            </div>

            <div className="btn-bottom">
                {/* <button onClick={handleAddMember}>Save</button>
                <button onClick={handleClose}>Cancel</button> */}
                 {(!jobTitle)?<button onClick={handleAddMember} className="submit-edit-errorbutton">Save</button>:
                  <button onClick={handleAddMember} className="submit-edit-button">Save</button>}
                {/* <button onClick={handleAddMember} className="submit-edit-button">Save</button> */}
        <button onClick={handleClose} className="cancel-edit-button">Cancel</button>
            </div>
        </div>
    );
}

export default EditRole;
