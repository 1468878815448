import React from 'react'
import { useNavigate } from 'react-router-dom'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'

function NoCompanyAddress({onclose}) {
    const nav = useNavigate()


    return (
        <div className="create-part-number-popup-page">
            <div className='navigate-to-company'>
                <div className='create-part-num-div-top'>
                    <span>Fill company address</span>
                    <PopupCloseButton

                        handleClose={onclose}
                    />
                </div>
                <div className='navigate-message'>
                    Please fill the address to create PO
                </div>
                <div className='navigate-btns-address'>
                    
                    <button className="inventory-go-back-button" onClick={()=>onclose()}>Cancel</button>
                    <button className="inventory-confirm-button" onClick={()=>nav('/settings?tab=company')}>Add address</button>
                </div>
            </div>
        </div>
    )
}

export default NoCompanyAddress