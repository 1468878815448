import React, { useState, useEffect } from "react";
import "./General.css";
import {
  BASE_URL,

 
  ASSET_PREFIX_URL,

} from "../../../constants/config";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "../../Common/Loading/Loading";
import PhotoEditPopUp from "./PhotoEditPopUp";
import NameProfile from "../../Common/NameProfile/NameProfile";

function General() {
  const [loading, setLoading] = useState(false)
  const [nameEdit, setNameEdit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  
 
  const [profilePicture, setProfilePicture] = useState("");
  const [isAdmin, setIsAdmin] = useState(false); // Initialize as false
  const [photoFile, setPhotoFile] = useState('')
  const [editPhotoOpen, setPhotoEditOpen] = useState(false)

  useEffect(() => {
    fetchMemberDetails();
  }, []);

  const getMenu = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", {
        headers,
      });
      const data = response.data.data;
      localStorage.setItem(
        "orgDetails",
        JSON.stringify(data.org_details.orgDetails)
      );
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(data.profile_details)
      );
      localStorage.setItem(
        "starredDepartments",
        JSON.stringify(data.star_depart)
      );
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const fetchMemberDetails = async () => {
    try {
      setLoading(true)
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(
        BASE_URL + "/v1/setting/get-profile-details",
        { headers: headers }
      );
      const required = response.data.data;
      console.log(required)
      setIsAdmin(required.person_details.is_admin);
      // setCompany(required.business_details.name);
      // setCompanyLogo(required.business_details.logo);
      setEmail(required.person_details.email);
      setName(required.person_details.fullName);
      setProfilePicture(required.person_details.photo);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  const handleClosePopup = () => {
    setPhotoEditOpen(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setPhotoFile(file)
    setPhotoEditOpen(true)
    
  };

 

  const updateProfile = async (photo) => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
       await axios.post(
        BASE_URL + "/v1/setting/edit-name-photo",
        { photo, fullName: name },
        { headers }
      );
      await getMenu();
      window.location.reload();
      // Handle the response if needed
    } catch (error) {
      console.error(error);
    }
  };

 

  return (
    <>
      {loading ? <Loading /> :
        <div width="100%">
          <div className="general-page">
            <div className="general-profiels">
              
              <div className="general-upload-con">
                <span>Profile Photo</span>
                <div className="general-upload">              
                            <NameProfile userName={name}  memberPhoto={profilePicture} width="100px" fontSize='38px' fontweight='500' />
                  <button
                    onClick={() => document.getElementById("fileupld").click()}
                  >
                    
                    <img
                      src={`${ASSET_PREFIX_URL}upload-plus.svg`}
                      width="16px"
                      height="16px"
                      alt=""
                    />
                  </button>

                </div>
              </div>
              <input
                type="file"
                id="fileupld"
                accept="image/jpeg, image/png, image/gif"
                style={{ display: "none" }}
                onChange={handleFileUpload}
                className="btn-upload"
              />
            </div>
           
            <div className="general-company-name">
              <span className="general-company-name">Name</span>
              <div className="general-company-name-input">
                {nameEdit ? (
                  <>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <button
                      onClick={() => {

                        updateProfile();
                      }}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <input type="text" disabled value={name} />

                    <button onClick={() => setNameEdit(true)}>
                      <img src={`${ASSET_PREFIX_URL}general-edit.svg`}  alt=""/> Edit
                    </button>

                  </>
                )}
              </div>
            </div>
            <div className="general-company-name">
              <span className="general-company-name">Email</span>
              <div className="general-company-name-input">
                <input type="text" disabled value={email} />
              </div>
            </div>
          </div>
        </div>

      }
      {editPhotoOpen && <PhotoEditPopUp ProfilePhoto={photoFile} name={name} onClose={handleClosePopup} />}
    </>

  );
}

export default General;
