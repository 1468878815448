import React,{useState} from 'react'
import { BASE_URL,ASSET_PREFIX_URL,PHOTO_LINK,TICKET_ATTACHMENT_BUCKET } from '../../constants/config';
import axios from 'axios';
import  Cookies  from 'js-cookie';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function AttachmentsGRN({ponumber,shouldEdit=true,data, fetchPartsGrnDetails}) {
    const [uploadError, setUploadError] = useState('');
    const [fileSizeError, setFileSizeError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
    
        handleFile(file);
      };
      const upload = () => {
        document.getElementById("fileupld").click();
      };
      const handleFileDrop = async (event) => {
        event.preventDefault();
        // setDragging(false);
        if (shouldEdit) {
          const files = event.dataTransfer.files;
          if (files.length > 0) {
            const file = files[0];
    
            handleFile(file);
          }
        }
    
      };
      const handleDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
      };
      const getFileIcon = (fileName) => {
        // console.log(fileName)
        const extension = fileName.split(".").pop().toLowerCase();
    
        if (extension === "pdf") {
          return (
            <div
    
              style={{ overflow: 'hidden', border: 'none', }} className="extension-type-div">
              <iframe
                src={PHOTO_LINK + fileName}
                title="CSV Preview"
              //   width= '75px'
              // height= '75px'
              // border= 'none'
              // style={{ overflowY: 'hidden', border: 'none',padding:'0px' }}
              // scrolling="no"
              />
            </div>
    
          );
        }
        else if (extension === "xls" || extension === "xlsx") {
          return (
            <img
              src={`${ASSET_PREFIX_URL}xlsFile.png`}
              alt="Excel"
              width="75px"
              height="75px"
            />
          );
        }
        else if (extension === "xml") {
          return (
            <img
              src={`${ASSET_PREFIX_URL}xmlFile.png`}
              alt="XML"
              width="75px"
              height="75px"
            />
          );
        } else if (extension === "jpg" || extension === 'png' || extension === 'jpeg' || extension === 'svg') {
          return (
            <img src={PHOTO_LINK + fileName} alt=""
              className="png-img-div" />
          );
        }
        else if (extension === "pptx") {
          return (
            <img
              src={`${ASSET_PREFIX_URL}pptxFile.png`}
              alt="ppt"
              width="75px"
              height="75px"
            />
          );
        }
        else if (extension === "csv") {
          return (
            <img
              src={`${ASSET_PREFIX_URL}csvFile.png`}
              alt="csv"
              width="75px"
              height="75px"
            />
          );
        }
        else if (extension === "docx") {
          return (
            <img
              src={`${ASSET_PREFIX_URL}wordFile.png`}
              alt="csv"
              width="75px"
              height="75px"
            />
          );
        } else {
          return <img src={`${ASSET_PREFIX_URL}filesImage.png`} alt="File" width="75px"
            height="75px" />;
        }
      };
    
      const handleFile = async (file) => {
        setFileSizeError('');
        setUploadError('');
        const fileSizeMB = file.size / (1024 * 1024);  // size in MB
        if (fileSizeMB > 2) {
          setFileSizeError('File size cannot be more than 2 MB');  // Notify the user
          return;  // Exit the function
        }
        //fetch pre signed URL
        setIsLoading(true);
        try {
          const headers = {
            'x-auth-token': Cookies.get('token')
          };
          const preSignedURL = await axios.post(`${BASE_URL}/v1/member/get-presigned-url`, { bucket_name: TICKET_ATTACHMENT_BUCKET, file: file.name }, { headers: headers })
          if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
            //upload to s3
            await axios.put(preSignedURL.data.data.url, file, {
              headers: {
                'Content-Type': file.type
              }
            });
            // When uploading a new file, add it to the attachments array
    
            // If editing, send the updated attachments array along with other updates
            const updatedAttachments = [...data, { name: file.name, key: preSignedURL.data.data.key }];
            await axios.post(
              `${BASE_URL}/v1/goods-receipts/add-attachments-grn`,
              { id: ponumber, data: JSON.stringify(updatedAttachments) },
              { headers }
            );
            fetchPartsGrnDetails()
            // Handle the response as needed
    
          } else {
            alert('Error generating signed URL')
          }
        } catch (e) {
          setUploadError('Error in uploading file');
        } finally {
          // Set isLoading back to false after upload (whether success or failure)
          setIsLoading(false);
        }
      }
    return (
        <>
         <div className="create-part-num-div-form-label-attahment">
            <span>Upload tax invoice</span>
            <div className="filedropzone" onDrop={handleFileDrop}
                onDragOver={handleDragOver}
            >
                <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                {isLoading ? <p>Uploading ....</p> :
                    <p>Drop files to attach or<span onClick={upload}> Browse</span></p>}
            </div>
            <input
                type="file"
                id="fileupld"
                accept="image/jpeg, image/png, image/gif"
                style={{ display: "none" }}
                disabled={!shouldEdit}
                onChange={handleFileUpload}
                className="btn-upload"
            />
        </div>
         {!uploadError && <div className="error">{uploadError}</div>}
         {fileSizeError && <div className="error"><span className="error-message"
           style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {fileSizeError}</span></div>}
         <div className="uploaded-files-view" style={{ marginBottom: '25px' }}>
           {data.map((file, index) => (
             <div key={index} className="uploaded-file-view">
               <div className="file-details-view" >

                 <div className="file-image-preview">
                   {getFileIcon(file.key)}
                 </div>
                 {/* <img src={PHOTO_LINK+file.key} width='80px'/> */}
                 <div className="upld-file-details-view">


                   <span title={file.name} className="file-view-name">{file.name.length > 20 ? file.name.substring(0, 15) + '...' : file.name}</span>




                 </div>

                 <div className="file-details-view-hover">
                   <a href={PHOTO_LINK + file.key} target="_blank" rel="noreferrer" >
                     <div className="file-box-link">
                       <span title={file.name} className="file-view-name-view">{file.name.length > 20 ? file.name.substring(0, 18) + '...' : file.name}</span>

                     </div>
                   </a>
                   {/* {editable ? <> */}
                   <div className="file-delete">
                     <DeleteOutlineOutlinedIcon
                    //    onClick={() => deleteAttachment(file.key)}
                     />
                   </div>

                   {/* </> : ''} */}
                 </div>
               </div>
             </div>
           ))}

         </div>
        </>
       
    )
}

export default AttachmentsGRN