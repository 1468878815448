import React, { useState, useEffect, useRef } from 'react'
import {
  BASE_URL
} from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import Loading from '../Common/Loading/Loading';
 
function DevEcList({ editable, sequence_id, searchedEc, type, ticketId, }) {

  const [searchEc, setSearchEc] = useState(searchedEc ? searchedEc : '');
  const [selectedEcs, setSelectEcs] = useState([]);
  const [searchEcLoading, setSearchEcLoading] = useState(false);
  const [ecsList, setEcsList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [ecDetailsCondition, setEcDetailsCondition] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const LIMIT = 6;
  const containerRef = useRef(null);

  const handleSeachEcs = (e) => {
    const searchParameter = e.target.value;
    setSearchEc(searchParameter);
    if (searchParameter === '') {
      setEcsList([])
      setActivePage(1)
      setSearchEcLoading(true)
      setEcDetailsCondition(false)
    } else {
      setEcsList([])
      setActivePage(1)
      setSearchEcLoading(true)
      setEcDetailsCondition(true)
    }
  };

  useEffect(() => {
    if (searchEc !== '') {
      const delayTimer = setTimeout(() => {
        fetchEcs();
      }, 2000);

      return () => clearTimeout(delayTimer);
    }
    else {
      fetchDevEcs();
    }
  }, [activePage, searchEc]);

  const fetchEcs = async () => {
    try {
      setSearchEcLoading(true)
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };

      if (type === 'ec-deviation') {
        const listofUniquePartNumber = await axios.get(
          `${BASE_URL}/v1/ec-deviations/get-unique-ec`,
          {
            params: { page: activePage, limit: LIMIT, search: searchEc },
            headers: headers,
          }
        );

        setEcsList(listofUniquePartNumber.data.data.uniqueECTitles)
        setHasMore(listofUniquePartNumber.data.data.uniqueECTitles === LIMIT)
      }
      setSearchEcLoading(false);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  const fetchDevEcs = async () => {
    try {
      setSearchEcLoading(true)
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };

      if (type === 'ec-deviation') {
        const listOfEcsInDev = await axios.get(
          `${BASE_URL}/v1/ec-deviations/get-dev-ecs`,
          {
            params: { sequence_id, page: activePage, limit: LIMIT },
            headers: headers,
          }
        );

        setEcsList(listOfEcsInDev.data.data.dev_ec_list)
        setHasMore(listOfEcsInDev.data.data.dev_ec_list === LIMIT)
      }
      setSearchEcLoading(false);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };


  const handleUpdateDeleteEc = async (action) => {
    console.log(selectedEcs)
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const getpartsDetails = await axios.post(
        `${BASE_URL}/v1/ec-deviations/update-ec-list-in-dev`,
        {
          sequence_id,
          ec_data: selectedEcs, // Pass selected ECs here
          action
        },
        { headers: headers }
      );
      //   fetchFormDetails();
      setSearchEc('')
      window.location.reload()
      // Handle response or do something with getpartsDetails if needed
    } catch (error) {
      console.log(error);
    }
  };
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const handleScroll = debounce(() => {
    const container = containerRef.current;
    if (!hasMore || searchEcLoading) {

      return;
    }


    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
      setActivePage(prevPage => prevPage + 1);
    }
  }, 500);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerRef, handleScroll]);
  return (
    <div style={{ width: '100%' }}>
      {editable && <div className="ticket-assign-page-filters-search" style={{ marginBottom: '20px' }}>
        <SearchIcon style={{ color: "#001325" }} />
        <input type="text" placeholder="Search EC to add... "
          value={searchEc} onChange={(e) => handleSeachEcs(e)}
        />
      </div>}


      {searchEcLoading ? <Loading smallScreen={true}/> :
        <div className="part-number-parts-divs-ec"
        // ref={containerRef} onScroll={handleScroll}
        >
          {/* <InfiniteScroll
          dataLength={partOptions.length}
          next={() => handleNextFunction()}
          hasMore={partOptions.length > optionsCount}
          loader={
            <Loading />
          }
        // className="infinite-scroll-content"
        > */} 
          {(ecsList.length === 0 && !searchEcLoading) ? <div className='data-not-found-img'>
            <span>EC not found!</span>
          </div> : <>
            {ecsList.map((item) => (
              <div key={item.ec_id} className="part-numbers-views-ec">
                <div className="part-numbers-views-details-ec">
                  {editable && <div className="part-numbers-views-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setSelectEcs(prevSelectedEcs => {
                          if (e.target.checked) {

                            return [...prevSelectedEcs, item];
                          } else {

                            return prevSelectedEcs.filter(selectedItem => selectedItem.ec_sequence_id !== item.ec_sequence_id);
                          }

                        });
                      }}
                    />
                  </div>}

                  <div className="part-numbers-views-content-title">
                    <span className="part-number-view-number">{item.ec_title}</span>
                    <a href={`/ec-ticket-view/${item.ec_sequence_id}`} target='_blank' className='link-to-view-ec'>{item.ec_sequence_id}</a>
                  </div>
                </div>
              </div>
            ))}
          </>}


          {/* </InfiniteScroll> */}
        </div>
      }
      <div>
        {(editable && !searchEcLoading) && (
          <>
            {ecDetailsCondition ? (
              <button
                className="part-view-delete-parts"
                onClick={() => handleUpdateDeleteEc('update', selectedEcs)}
                disabled={selectedEcs.length < 1}
              >
                Update
              </button>
            ) : (
              <button
                className="part-view-delete-parts"
                onClick={() => handleUpdateDeleteEc('remove', selectedEcs)}
                disabled={selectedEcs.length < 1}
              >
                Delete
              </button>
            )}
          </>
        )}




      </div>
    </div>
  )
}

export default DevEcList