import React, { useState, useEffect } from "react";
import "./Edit.css";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import axios from "axios";
import { BASE_URL, DEFAULT_PHOTO, PHOTO_LINK, ASSET_PREFIX_URL } from "../../../constants/config";
import Cookies from "js-cookie";
import NameProfile from "../NameProfile/NameProfile";

function RemoveChangeManager({activeNode, hierarchy, setAction}) {
  // const allIds = [activeNode.entity_id].concat(activeNode.children.map(item => item.entity_id));
  
  // console.log(allIds);
  const collectAllIds = (node) => {
    let ids = [node.entity_id];
    if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
            ids = ids.concat(collectAllIds(child));
        });
    }
    return ids;
};

// Collect all entity IDs from hierarchy
const allIds = collectAllIds(activeNode);

console.log(allIds);
  const [close, setClose] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});



  useEffect(() => {
    fetchData();
  }, []);




  const fetchData = async () => {
    try {
      const headers = {
        'x-auth-token': Cookies.get("token")
      };
      const response = await axios.get(BASE_URL + "/v1/org/get-change-manager",
      {params:{entity_ids:allIds }, headers: headers });
      //  console.log(response.data.data);
      setOptions(response.data.data);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };


  const handleClose = () => {
    setClose(true);
    setAction(false)
  };

  const handleAddMember = async ()  => {
    setFormSubmitted(true);
    setValidationErrors({});

    // Validate inputs
    if (!selectedOption) {
      setValidationErrors(prevErrors => ({ ...prevErrors, selectedOption: "Please select an employee." }));
      return;
    }


    try {
        const selectedEntityId = selectedOption ? selectedOption.entity_id : "";
        // Get the selected email from the option
        await axios.delete(BASE_URL + "/v1/org/remove-role", {
          data:{
              entity_id: activeNode.entity_id,
              new_manager_id: selectedEntityId,
              parent_id:activeNode.parent_entity_id
          },
             headers: {
                 "x-auth-token": Cookies.get('token'),
             }});
        window.location.reload();
      }
      // Handle the response data or update the UI accordingly
     catch (error) {
      console.error(error.message);
     
      // Handle the error or display an error message
    }
  };

  const filterOptions = (candidate, input) => {
    if (!input) {
      return true;
    }

    const inputValue = input.toLowerCase().trim();
    const fullName = candidate.data.fullName.toLowerCase();
    return fullName.includes(inputValue);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,

      overflowY: "auto",
      overflow: "hidden", // Disable the default browser scrollbar
    }),
    menuList: (provided) => ({
      ...provided,
      "::-webkit-scrollbar": "auto",
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white", // Set the background color
      color: state.isSelected ? "black" : "inherit", // Set the text color
    }),
  };


  return (
    <div className="editRole" style={{ display: close ? "none" : "block" }}>
    <div className="upper-part">
      <div className="cancel">
        <span>Change Manager before removing</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className="emp">
        <span>Employee</span>
        <Select
          id="mySelect"
          menuPlacement="auto"
          styles={customStyles}
          options={options}
          getOptionLabel={(option) => (
            <div className="select-photo">
            <NameProfile userName={option.fullName} width='25px' memberPhoto={option.photo} />

            

             {option.fullName}&nbsp;
             ({option.email.length > 12 ? `${option.email.slice(0, 15)}...` : option.email})
        </div>
          )}
          onChange={(selectedOption) => {
            setSelectedOption(selectedOption); // Update the selected option
          }}
          filterOption={filterOptions} // Update the selected option
          value={selectedOption} // Set the selected option
        />
        {formSubmitted && validationErrors.selectedOption && (
            <div className="department-error"><img src={`${ASSET_PREFIX_URL}warning.svg`} alt=""/>&nbsp;&nbsp;&nbsp;{validationErrors.selectedOption}</div>
          )}
      </div>
    </div>

    <div className="btn-bottom">
      {/* <button onClick={handleAddMember}>Save</button>
      <button onClick={handleClose}>Cancel</button> */}
      <button onClick={handleAddMember} className="submit-edit-button">Save</button>
        <button onClick={handleClose} className="cancel-edit-button">Cancel</button>
    </div>
  </div>
  );
}

export default RemoveChangeManager;