import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signup from "./Signup/Signup";
import Login from "./Login/Login";
import SetupPassword from "./SetupPassword/SetupPassword";
import Profile from "./Profile/Profile";
import Bussiness from "./BussinessPage/Bussiness";
import ResendInvite from "./ResendInvite/ResendInvite";
import Hierarchy from "./Common/Orghierarchy/Hierarchy";
import TicketTemplate from "./TicketComponent/TicketTemplate";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TicketAssignTemplate from "./TicketComponent/TicketAssignTemplate";
import TemplatePage from "./TicketComponent/TemplatePage";
import TicketHome from "./Ticket/TicketHome";
import DepartmentList from "./DepartmentPages/DepartmentList";
import Overview from "./Overview/Overview";
import SettingHome from "./settingsPages/SettingsHome/SettingHome";
import TicketViews from "./Ticket-Views/TicketViews";
import AllTicketList from "./TicketList/AllTicketList";
import RouteHandler from "./AxiosResponseHandler";
import WelcomeAboard from "./WelcomeAboard/WelcomeAboard";
import AddMembers from "./AddMembers/AddMembers";
import { departmentSteps, emptyTemplateSteps, emptyTicketSteps, hierarchyAssitantSteps, hierarchyEmptySteps, hierarchySteps, templateSteps, ticketSteps } from "./Common/TourGuides/TourSteps";
import Home from "./Home/Home";
import PartNumber from "./PartNumbers/PartNumber";
import PartNumberList from "./PartNumbers/PartNumberList"
import PartNumberView from "./PartNumbers/PartNumberView";
import AllEcDeviations from "./TicketList/AllEcDeviations";
import AllDeviations from "./TicketList/AllDeviations";
import AllSuppliers from "./TicketList/AllSuppliers";
import AllPoList from "./TicketList/AllPoList";
import PoCreate from "./PoCreationFolder/PoCreate";
import GrnParts from "./TicketList/GrnParts";
import SupplierPageView from "./SupplierFolder/SupplierPageView";
import InventoryPopUps from "./PopUpsFolder/InventoryPopUps";
import InventoryList from "./TicketList/InventoryList";
import InventoryView from "./TicketList/InventoryView";
import AllAssetsList from "./TicketList/AllAssetsList";
import AssetsNumberList from "./PartNumbers/AssetsNumberList";
import AssetNumberView from "./PartNumbers/AssetNumberView";
import NoCompanyAddress from "./PopUpsFolder/NoCompanyAddress";








function Marathon() {
  const toastStyle = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <Router>
          <Routes>
            <Route path="/tck-hm" element={<TicketHome />} />
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Signup toastStyle={toastStyle} />} />
            <Route path="/login" element={<Login />} toastStyle={toastStyle} />
            <Route
              path="/setup-password"
              element={
                <RouteHandler>
                  <SetupPassword toastStyle={toastStyle} />
                </RouteHandler>
              }
            />
            <Route
              path="/profile-details"
              element={
                <RouteHandler>
                  <Profile toastStyle={toastStyle} />
                </RouteHandler>
              }
            />
            <Route
              path="/org-details"
              element={
                <RouteHandler>
                  <Bussiness toastStyle={toastStyle} />
                </RouteHandler>
              }
            />
            <Route path="/welcome" element={<WelcomeAboard />} />
            <Route path="/add-members" element={<AddMembers />} />

            <Route
              path="/resent-invite"
              element={
                <RouteHandler>
                  <ResendInvite />
                </RouteHandler>
              }
            />

            <Route
              path="/template"
              element={
                <RouteHandler>
                  <TicketTemplate
                    templateSteps={templateSteps}
                    emptyTemplateSteps={emptyTemplateSteps}
                  >
                    <TicketAssignTemplate
                      type='template'
                      templateSteps={templateSteps}
                      emptyTemplateSteps={emptyTemplateSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />


            <Route
              path="/template-fields/:templateId"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TemplatePage toastStyle={toastStyle} type='template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/ec-template"
              element={
                <RouteHandler>
                  <TicketTemplate
                    templateSteps={templateSteps}
                    emptyTemplateSteps={emptyTemplateSteps}
                  >
                    <TicketAssignTemplate
                      type='ec-template'
                      templateSteps={templateSteps}
                      emptyTemplateSteps={emptyTemplateSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/assets-template"
              element={
                <RouteHandler>
                  <TicketTemplate
                    templateSteps={templateSteps}
                    emptyTemplateSteps={emptyTemplateSteps}
                  >
                    <TicketAssignTemplate
                      type='assets-template'
                      templateSteps={templateSteps}
                      emptyTemplateSteps={emptyTemplateSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/supply-template-fields/:templateId"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TemplatePage toastStyle={toastStyle} type='supply-template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/purchase-order-template-fields/:templateId"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TemplatePage toastStyle={toastStyle} type='po-template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/assets-template-fields/:templateId"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TemplatePage toastStyle={toastStyle} type='assets-template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/supply-chain-templates"
              element={
                <RouteHandler>
                  <TicketTemplate
                    templateSteps={templateSteps}
                    emptyTemplateSteps={emptyTemplateSteps}
                  >
                    <TicketAssignTemplate
                      type='supply-template'
                      templateSteps={templateSteps}
                      emptyTemplateSteps={emptyTemplateSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/purchase-order-templates"
              element={
                <RouteHandler>
                  <TicketTemplate
                    templateSteps={templateSteps}
                    emptyTemplateSteps={emptyTemplateSteps}
                  >
                    <TicketAssignTemplate
                      type='po-template'
                      templateSteps={templateSteps}
                      emptyTemplateSteps={emptyTemplateSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/ec-template-fields/:templateId"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TemplatePage toastStyle={toastStyle} type='ec-template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/overview"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <Overview />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/org-home"
              element={
                <RouteHandler>
                  <TicketTemplate
                    heirarchySteps={hierarchySteps}
                    hierarchyAssitantSteps={hierarchyAssitantSteps}
                    hierarchyEmptySteps={hierarchyEmptySteps}
                  >
                    <Hierarchy
                      heirarchySteps={hierarchySteps}
                      hierarchyAssitantSteps={hierarchyAssitantSteps}
                      hierarchyEmptySteps={hierarchyEmptySteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/ticket"
              element={
                <RouteHandler>
                  <TicketHome />
                </RouteHandler>
              }
            />
            <Route
              path="/settings"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <SettingHome toastStyle={toastStyle} />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/department-ticket/:department/:id"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <AllTicketList type='template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/depart-list"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <DepartmentList
                      toastStyle={toastStyle}
                      departmentSteps={departmentSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/ticket-view/:sequence_id"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TicketViews toastStyle={toastStyle} type='template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/ec-ticket-view/:sequence_id"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TicketViews toastStyle={toastStyle} type='ec-template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/deviation-ticket-view/:sequence_id"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <TicketViews toastStyle={toastStyle} type='ec-deviation' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/all-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    <AllTicketList
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                      type='template'
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/all-ec-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    {/* <EcPageForm
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                      type='ec-template'
                    /> */}
                    <AllEcDeviations type='ec-template' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/all-deviation-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    {/* <EcPageForm
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                      type='ec-template'
                    /> */}
                    <AllDeviations type='ec-deviation' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/all-supplier-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    {/* <EcPageForm
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                      type='ec-template'
                    /> */}
                    <AllSuppliers type='supplier' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/all-po-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    {/* <EcPageForm
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                      type='ec-template'
                    /> */}
                    <AllPoList type='po' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/assets-parts-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    {/* <EcPageForm
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                      type='ec-template'
                    /> */}
                    <AssetsNumberList type='assets' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/part-number"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    <PartNumber
                      type='part-num'


                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/po-number"
              element={
                <RouteHandler>
                  <TicketTemplate

                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    <PartNumber
                      type='po-num'


                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/assets-number"
              element={
                <RouteHandler>
                  <TicketTemplate

                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    <PartNumber
                      type='assets-num'


                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />



            <Route
              path="/part-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    <PartNumberList
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/assets-list"
              element={
                <RouteHandler>
                  <TicketTemplate
                    ticketSteps={ticketSteps}
                    emptyTicketSteps={emptyTicketSteps}
                  >
                    <AllAssetsList
                      ticketSteps={ticketSteps}
                      emptyTicketSteps={emptyTicketSteps}
                    />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/part-view/:id"
              element={
                <RouteHandler>

                  <TicketTemplate>
                    <PartNumberView toastStyle={toastStyle} />
                  </TicketTemplate>

                </RouteHandler>
              }
            />
             <Route
              path="/asset-view/:id"
              element={
                <RouteHandler>

                  <TicketTemplate>
                    <AssetNumberView toastStyle={toastStyle} />
                  </TicketTemplate>

                </RouteHandler>
              }
            />
            <Route
              path="/po-view/:sequence_id"
              element={
                <RouteHandler>

                  <TicketTemplate>
                    <PoCreate poType='view' toastStyle={toastStyle} />
                  </TicketTemplate>

                </RouteHandler>
              }
            />
            <Route
              path="/po-asset-view/:sequence_id"
              element={
                <RouteHandler>

                  <TicketTemplate>
                    <PoCreate poType='assets-view' toastStyle={toastStyle} />
                  </TicketTemplate>

                </RouteHandler>
              }
            />
            <Route
              path="/parts-grn-view/:sequence_id"
              element={
                <RouteHandler>

                  <TicketTemplate>
                    <PoCreate poType='parts-grn-view' toastStyle={toastStyle} />
                  </TicketTemplate>

                </RouteHandler>
              }
            />
            <Route
              path="/create-po"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <PoCreate poType='create' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/create-asset"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <PoCreate poType='assets' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/all-grn-parts"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <GrnParts type='all-grn' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/all-grn-parts/:ponumber"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <GrnParts type='po-num' />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/supplier-view/:sequence_id"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <SupplierPageView />
                  </TicketTemplate>
                </RouteHandler>
              }
            />

            <Route
              path="/all-inventory"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <InventoryList />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/inventory-log/:part_number"
              element={
                <RouteHandler>
                  <TicketTemplate>
                    <InventoryView />
                  </TicketTemplate>
                </RouteHandler>
              }
            />
            <Route
              path="/dummy"
              element={
                <TicketTemplate>
                  <NoCompanyAddress />
                </TicketTemplate>


              }
            />


          </Routes>
        </Router>
      </div>
    </DndProvider>
  );
}

export default Marathon;
