import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './PoCreate.css';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import Loading from '../Common/Loading/Loading';

function PoExcelSheet({ defaultFields = [], setTableDetails, savedData = [], inputDisable, poType,
    errors, setErrors
 }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [srNoColumnIndex, setSrNoColumnIndex] = useState(-1);
    const [totalAmount, setTotalAmount] = useState(0);
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!Array.isArray(defaultFields) || defaultFields.length === 0) {
            console.error('defaultFields is not an array or is empty:', defaultFields);
            return;
        }

        const filteredColumns = defaultFields.filter(field => field.template_type === 'poItems' &&
            ((field.is_default === false && field.is_default_template === true)));

        const orderedColumns = orderColumns(filteredColumns);
        const srNoIndex = orderedColumns.findIndex(field => field.fieldName === 'Sr.no');

        setColumns(orderedColumns);
        setSrNoColumnIndex(srNoIndex);
        setRows(savedData.length > 0 ? savedData : generateInitialData(orderedColumns));
    }, [defaultFields]);

    useEffect(() => {
        if (!Array.isArray(rows) || rows.length === 0 || !Array.isArray(columns) || columns.length === 0) {
            console.error('rows or columns is not an array or is empty:', rows, columns);
            return;
        }

        const formattedData = rows.map(row => {
            const formattedRow = {};
            columns.forEach(column => {
                formattedRow[column.fieldName.toLowerCase().replace(/\s+/g, '_')] = {
                    value: row.data[column.fieldName],
                    ...column
                };
            });
            return formattedRow;
        });

        const validationErrors = validateRows(rows, columns);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setTableDetails(formattedData);
        }
        calculateTotalAmount();
    }, [rows, columns, setTableDetails]);

    const orderColumns = (columns) => {
        const ordered = [];
        const otherFields = [];

        columns.forEach(column => {
            otherFields.push(column);
        });

        return [...otherFields];
    };

    const generateInitialData = (columns) => {
        if (!Array.isArray(columns) || columns.length === 0) {
            console.error('columns is not an array or is empty:', columns);
            return [];
        }

        return [{
            id: Date.now(),
            data: columns.reduce((acc, column) => {
                acc[column.fieldName] = column.fieldName.toLowerCase() === 'sr.no' ? '1' : '';
                return acc;
            }, {})
        }];
    };

    const validateRows = (rows, columns) => {
        const errors = {};
        rows.forEach((row, rowIndex) => {
            columns.forEach(column => {
                if (!row.data[column.fieldName] && column.fieldName.toLowerCase() !== 'sr.no') {
                    if (!errors[rowIndex]) {
                        errors[rowIndex] = {};
                    }
                    errors[rowIndex][column.fieldName] = true;
                }
            });
        });
        return errors;
    };

    const handleCellChange = (rowIndex, fieldName, value) => {
        const newRows = [...rows];
        newRows[rowIndex].data[fieldName] = value;
        setRows(newRows);

        if (fieldName.toLowerCase() === 'part number') {
            fetchSuggestions(value, rowIndex);
        }

        if (fieldName.toLowerCase() === 'totalcost') {
            calculateTotalAmount(newRows);
        }
    };

    const fetchSuggestions = async (searchValue, rowIndex) => {
        setLoading(true);
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let response;
            if (poType === 'create') {
                response = await axios.get(`${BASE_URL}/v1/purchase-order/get-part-po`, {
                    params: { search: searchValue, page: 1, size: 10 },
                    headers
                });
                setSuggestions(response.data.data.parts.filter(part => !isPartNumberInTable(part.part_number)));
            } else {
                response = await axios.get(`${BASE_URL}/v1/asset/get-asset-in-asset-po`, {
                    params: { search: searchValue, page: 1, size: 10 },
                    headers
                });
                setSuggestions(response.data.data.asset.filter(asset => !isPartNumberInTable(asset.asset_number)));
            }

            setActiveSuggestionIndex(rowIndex);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    const isPartNumberInTable = (partNumber) => {
        return rows.some(row => row.data['Part number'] === partNumber);
    };

    const handleSuggestionClick = (rowIndex, suggestion) => {
        const newRows = [...rows];
        const descriptionField = columns.find(col => col.fieldName.toLowerCase() === 'part number');
        const titleField = columns.find(col => col.fieldName.toLowerCase() === 'description');

        if (descriptionField) {
            newRows[rowIndex].data[descriptionField.fieldName] = poType === 'create' ? suggestion.part_number : suggestion.asset_number;
        }
        if (titleField) {
            newRows[rowIndex].data[titleField.fieldName] = poType === 'create' ? suggestion.part_title : suggestion.asset_title;
        }

        setRows(newRows);
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
    };

    const addRow = () => {
        const newRow = {
            id: Date.now(),
            data: columns.reduce((acc, column) => {
                acc[column.fieldName] = column.fieldName.toLowerCase() === 'sr.no' ? (rows.length + 1).toString() : '';
                return acc;
            }, {})
        };
        setRows(prevRows => {
            const updatedRows = [...prevRows, newRow];
            if (srNoColumnIndex !== -1) {
                updatedRows.forEach((row, index) => {
                    row.data[columns[srNoColumnIndex].fieldName] = (index + 1).toString();
                });
            }
            return updatedRows;
        });
    };

    const removeRow = (rowId) => {
        if (rows.length > 1) {
            const newRows = rows.filter(row => row.id !== rowId);
            if (srNoColumnIndex !== -1 && columns[srNoColumnIndex]) {
                newRows.forEach((row, index) => {
                    row.data[columns[srNoColumnIndex].fieldName] = (index + 1).toString();
                });
            }
            setRows(newRows);
            calculateTotalAmount(newRows);
        }
    };

    const calculateTotalAmount = (rowsData = rows) => {
        const total = rowsData.reduce((sum, row) => {
            const totalCost = parseFloat(row.data['Total cost']) || 0;
            return sum + totalCost;
        }, 0);
        setTotalAmount(total);
    };

    return (
        <div className="po-excel-sheet-container">
            <span>Item table</span>
            <table className="po-table" style={{ marginTop: '12px' }}>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.id} style={styles.tableHeader}>
                                {column.fieldName}
                            </th>
                        ))}
                        <th style={styles.tableHeader}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={row.id}>
                            {columns.map((column) => (
                                <td key={column.fieldName}>
                                    {column.fieldName.toLowerCase() === 'sr.no' ? (
                                        <input
                                            type="text"
                                            value={row.data[column.fieldName]}
                                            className="po-input"
                                            readOnly
                                            style={styles.input}
                                        />
                                    ) : (
                                        <div style={{ position: 'relative' }}>
                                            <input
                                                style={{
                                                    ...styles.input,
                                                    borderColor: errors[rowIndex] && errors[rowIndex][column.fieldName] ? 'red' : '#edf2f7'
                                                }}
                                                type="text"
                                                value={row.data[column.fieldName]}
                                                className="po-input"
                                                onChange={(e) => handleCellChange(rowIndex, column.fieldName, e.target.value)}
                                            />
                                            {column.fieldName.toLowerCase() === 'part number' && rowIndex === activeSuggestionIndex && (
                                                <div className="suggestions-container">
                                                    <div className="suggestions-box">
                                                        {loading ? (
                                                            <div className='loader-po-div'>
                                                                <div className="loader-po"></div>
                                                            </div>
                                                        ) : (
                                                            suggestions.length < 1 ? (
                                                                <div className="suggestion-item" style={{ width: '150px' }}>No parts!</div>
                                                            ) : (
                                                                suggestions.map((suggestion, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="suggestion-item"
                                                                        onClick={() => handleSuggestionClick(rowIndex, suggestion)}
                                                                    >
                                                                        {poType === 'create' ? (
                                                                            <>
                                                                                {suggestion.part_number}, {suggestion.part_title}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {suggestion.asset_number}, {suggestion.asset_title}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </td>
                            ))}
                            <td>
                                <img
                                    src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                    alt='delete'
                                    className="delete-icon"
                                    onClick={() => removeRow(row.id)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="spreadsheet-controls">
                <img
                    src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                    alt='add'
                    width='24px'
                    height='24px'
                    className="add-icon"
                    onClick={addRow}
                />
            </div>
        </div>
    );
}

const styles = {
    poTable: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        border: '1px solid #ddd',
    },
    tableHeader: {
        padding: '8px',
        border: '1px solid #ddd',
        backgroundColor: '#f0f0f0',
        textAlign: 'left',
    },
    tableCell: {
        padding: '8px',
        border: '1px solid #ddd',
    },
    input: {
        width: '90%',
        padding: '8px',
        border: '1px solid #edf2f7',
        borderRadius: '4px',
        background: 'white',
    },
    icon: {
        cursor: 'pointer',
    },
};

export default PoExcelSheet;
