import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import { DEFAULT_PHOTO, PHOTO_LINK } from "../../../constants/config";
import './ViewRole.css';
import NameProfile from '../NameProfile/NameProfile';

function ViewRole({ activeNode, setAction }) {
  const [close, setClose] = useState(false);


  const handleClose = () => {
    setClose(true);
    setAction(false)
  };




  return (
    <>

      <div className='viewrole-page' style={{ display: close ? "none" : "block" }}>
        <div className='viewrole-top'>
          <div className='viewrole-head'>
            <span>View role</span>
            <CloseIcon className='cancel-btn' onClick={handleClose} />
          </div>

          <div className='viewrole-photo-cont'>
          <NameProfile userName={activeNode.fullName} width='200px' memberPhoto={activeNode.photo} fontSize='100px' fontweight='500' />
            {/* <img className='viewrole-photo' src={activeNode.photo ? PHOTO_LINK + activeNode.photo : DEFAULT_PHOTO} alt='' /> */}
          </div>
          <div className='viewrole-details'>
            <div className='viewrole-name'>
              <span><b>{activeNode.fullName}</b></span>
              <span>{activeNode.jobTitle}</span>
            </div>

            <div className='viewrole-contact'>
              <span><b>Contact</b></span>
              <div className='viewrole-email'>
                <div className='viewrole-email-label'>
                  <DraftsOutlinedIcon />
                  <span>Email</span>
                </div>
                <div className='viewrole-email-detail'>
                  <span>{activeNode.email}</span>
                </div>
              </div>
              <div className='viewrole-phone'>
                <div className='viewrole-phone-label'>

                  <LocalPhoneOutlinedIcon />
                  <span>Phone</span>
                </div>
                <div className='viewrole-phone-detail'>
                  <span>{activeNode.phoneNumber}</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>


  );
}

export default ViewRole;
