import React, { useState, useEffect } from "react";
import "./UserPage.css";
import Members from "./Members";
import Invited from "./Invited";
// import Deactivated from "./Deactivated";
import AddMemberPopUp from "./PopUp/AddMemberPopUp";
import { BASE_URL, ASSET_PREFIX_URL } from "../../../constants/config";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from './../../Common/Loading/Loading';

function UsePage({ toastStyle }) {
  const [selectedSection, setSelectedSection] = useState("member");
  const [memberCount, setMemberCount] = useState(0);
  const [inviteCount, setInviteCount] = useState(0);
  const [addMemPopUp, setAddMemPopUp] = useState(false);
 
  const [invite, setInvite] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [admin, setAdmin] = useState(false)
  const [loading, setLoading] = useState(false)



  const handleSectionClick = (section) => {
    fetchMembers()
    setSelectedSection(section);
  };

  const handleAddMemberPopUp = () => {
    setAddMemPopUp(!addMemPopUp);
  }


  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      setLoading(true)
      const headers = {
        'x-auth-token': Cookies.get('token')
      };
      const response = await axios.get(BASE_URL + "/v1/setting/get-mem", { headers: headers });
      const inviteResponse = await axios.get(
        BASE_URL + "/v1/setting/get-invite-members",
        { headers }
      );
      console.log(inviteResponse)
      setMembersData(response.data.data.memberList);
      setAdmin(response.data.data.is_admin)
      setInvite(inviteResponse.data.data.invited_members);
      setInviteCount(inviteResponse.data.data.invited_members.length)
     
      setMemberCount(response.data.data.memberList.length)
      setLoading(false)

    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <div className="usage-page" > 
          <div className="usage-page-top-btns">
            <div className="left-btns">
              <button
                className={`usage-page-member${selectedSection === "member" ? " UsersSelected" : ""
                  }`}
                onClick={() => handleSectionClick("member")}
              >
                Member ({memberCount})
              </button>
              <button
                className={`usage-page-member${selectedSection === "invited" ? " UsersSelected" : ""
                  }`}
                onClick={() => handleSectionClick("invited")}
              >
                Invited ({inviteCount})
              </button>
              {/* <button
            className={`usage-page-member${
              selectedSection === "deactivated" ? " UsersSelected" : ""
            }`}
            onClick={() => handleSectionClick("deactivated")}
          >
            Deactivated
          </button> */}
            </div>
            {admin ? <button className="usage-page-add" onClick={handleAddMemberPopUp}>
              <img className="usage-page-add-img" src={`${ASSET_PREFIX_URL}add-member.svg`} alt="" width="24px" height="24px" />
              Add Member
            </button> : ''}

          </div>
          <div>
            {selectedSection === "member" && (
              <div className="general-settings">
                {loading ? <Loading /> : 
                <Members toastStyle={toastStyle} membersData={membersData} admin={admin} loading={loading} fetchMembers={fetchMembers} />}
              </div>
            )}

            {selectedSection === "invited" && (
              <div className="general-settings">
                {loading ? <Loading/> : <Invited invite={invite} loading={loading} />}
                
              </div>
            )}
            {/* {selectedSection === "deactivated" && (
          <div className="general-settings">
            <Deactivated />
          </div>
        )} */}
          </div>
        </div>
      </div>

      {addMemPopUp && <AddMemberPopUp fetchMembers={fetchMembers} />}
    </>
  );
}

export default UsePage;
