import React,{useState} from "react";
import "./HomeTopNav.css"
import {ASSET_PREFIX_URL} from "../../../constants/config"
import HomeLanding from "./HomeLanding";
import HomeNavs from "./HomeNavs";
import { useNavigate } from 'react-router-dom'

function HomeTopNav() {
  const [openMenu,setMenuOpen] = useState(false)
  const nav = useNavigate()
  const handleMenuOpen = () =>{
    setMenuOpen(true)
  }

  const handleCloseMenu = () =>{
    setMenuOpen(false)
  }
  return (
    <div className="home-page-1">
      <div className="home-page-container">
        <div className="home-page-top">
          <img src={`${ASSET_PREFIX_URL}marathon-logo.png`} alt=""/>
          <div className="home-page-navs">
            {/* list */}
            <a href="#team-rich">Team Rich</a>
            <a href="#capabilities">Capabilities</a>
            <a href="#compliance">Compliance</a>
            <a href="#security">Security</a>
          </div>
          <div className="home-pg-btns">
            {/* buttons */}
            <button className="try-demo">Try Demo</button>
            <button className="home-login"  onClick={()=>nav('/login')}>Login</button>
          </div>
          <div className="home-pg-menu" onClick={handleMenuOpen}>
          <img src={`${ASSET_PREFIX_URL}menu-icon.webp`} alt=""/>
          </div>
         
        </div>
        {openMenu && <HomeNavs onclose={handleCloseMenu}/>}
        <HomeLanding />
      </div>
    </div>
  );
}

export default HomeTopNav;
