import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Bomheirarchy.css";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./BomHelper";
import Cookies from "js-cookie";
import { BASE_URL } from "../../../constants/config";
import Loading from './../../Common/Loading/Loading';




const containerStyles = {
    width: "100%",
    height: "40vh",
};


const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
    // const nav = useNavigate()

    const handleNodeClick = () => {
        // Construct the URL for the new tab
        const url = `/part-view/${nodeDatum.part_number}`;

        // Open the URL in a new tab
        window.open(url, '_blank');
    };


    return (
        <g>
            <rect
                width="375"
                height="98"
                x="-200"
                y="-50"
                rx="8"
                ry="8"
                strokeWidth="1.75"
                onClick={toggleNode}
                fill="#fff"
                stroke="#b557a8"
                style={{
                    boxShadow: "0px 2px 4px 0px rgba(72, 123, 253, 0.10)",
                    border: "4.5px solid #b557a8",
                    stroke: "#b557a8",
                }}
            />
            {nodeDatum.count > 0 && <rect
                width="50"
                height="30"
                x="100"
                y="-40"
                rx="8"
                ry="8"
                strokeWidth="1.75"
                onClick={toggleNode}
                fill="#fff"
                stroke="#b557a8"
                style={{
                    boxShadow: "0px 2px 4px 0px rgba(72, 123, 253, 0.10)",
                    border: "4.5px solid #b557a8",
                    stroke: "#b557a8",
                }}
            />}

            {nodeDatum.count > 0 && <text
                fill="black"
                
                // onClick={handleNodeClick}
                strokeWidth="1"
                x="117"
                y="-42"
                dy="1em"
                textAnchor="start"
                fontSize="26px"
            >
                {nodeDatum.count}
            </text>}
            <text
                fill="blue" // Change fill color to blue
                onClick={handleNodeClick}
                strokeWidth="0.5"
                x="-160"
                y="-35"
                dy="1em"
                textAnchor="start"
                fontSize="26px"
                style={{ textDecoration: "underline" }} // Add underline text decoration
            >
                {nodeDatum.part_number}
            </text>

            <text
                fill="black"
                // onClick={handleNodeClick}
                strokeWidth="1"
                x="-160"
                y="5"
                dy="1em"
                textAnchor="start"
                fontSize="26px"
            >
                {nodeDatum.part_title}
            </text>
        </g>
    );
};


function BomHierarchy({ partNumber, selectedSection }) {
    const [treeData, setTreeData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchBOMhierarchy()
    }, []);
    const fetchBOMhierarchy = async () => {
        try {
            setLoading(true)
            const headers = {
                'x-auth-token': Cookies.get("token")
            };
            const response = await axios.get(BASE_URL + "/v1/part-nomen/bom-view", {
                headers,
                params: { partNumber, view: selectedSection }
            });
            console.log(response)
            setTreeData(response.data.data.bom_hierarchy);
            //   const data = response.data.data;
            //   setCollabAdmin(data.is_admin)
            setLoading(false)
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const [dimensions, translate, containerRef] = useCenteredTree();
    return (
        <>
        {loading ? <Loading excellLoading={true}/> : <div style={containerStyles} ref={containerRef}>
                <Tree data={treeData} orientation="vertical"
                    zoom={0.4}
                    // dimensions={dimensions}
                    translate={translate}
                    pathFunc="step"
                    separation={{ siblings: 3.25, nonSiblings: 3.5, parentChild: 200 }}
                    renderCustomNodeElement={renderRectSvgNode} />
            </div>}
            
        </>


    )
}

export default BomHierarchy