import React, { useState, useEffect } from 'react'
import {

    PHOTO_LINK,

    ASSET_PREFIX_URL,
} from "../../constants/config";

import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import NameProfile from '../Common/NameProfile/NameProfile';

function EcApproveList({ onclose, sequence_id, type, ticketId, prevObj ,approver}) {
    console.log(approver)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [searchMember, setSearchMember] = useState('');
    const [approvalMembers, setApprovalMembers] = useState([]);
    const [addApprovals, setAddApprovals] = useState([]);
    let typingTimeout = null;
    const toggleDropdown = () => {

        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        getApprovalListMembers()
    }, [])
    const getApprovalListMembers = async (searchQuery) => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            let approvalResponse;
            if (type === 'ec-template') {

                approvalResponse = await axios.get(
                    `${BASE_URL}/v1/ec-template/get-approve-list/${sequence_id}`,
                    { headers, params: { search: searchQuery } }
                );
            } else if (type === 'ec-deviation') {

                approvalResponse = await axios.get(
                    `${BASE_URL}/v1/ec-deviations/get-approve-list/${sequence_id}`,
                    { headers, params: { search: searchQuery } }
                );
            } else if (type === 'view') {

                approvalResponse = await axios.get(
                    `${BASE_URL}/v1/purchase-order/get-approve-list`,
                    { headers, params: { search: searchQuery, po_name: sequence_id } }
                );
            } else if (type === 'assets-view') {
                approvalResponse = await axios.get(
                    `${BASE_URL}/v1/asset/get-approve-list`,
                    { headers, params: { search: searchQuery, asset_name: sequence_id } }
                );
            }

            setApprovalMembers(approvalResponse.data.data.approval);
            setAddApprovals(approvalResponse.data.data.nonApprovalList);

        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setSearchMember(value);
        clearTimeout(typingTimeout);
        typingTimeout = setTimeout(() => {
            getApprovalListMembers(value);
        }, 2000);
    };
    const handleMemberClick = (member) => {

        const memberId = member.entity_id;
        const fullName = member.fullName;
        const photo = member.photo;


        if (selectedMembers.some(selectedMember => selectedMember._id === memberId)) {
            setSelectedMembers(selectedMembers.filter(selectedMember => selectedMember._id !== memberId));
        } else {
            setSelectedMembers([...selectedMembers, { _id: memberId, fullName, photo }]);
        }
       

    };
const handleRemove = async(item) =>{
    if(approvalMembers.length >1){
        try {
            const headers = {
                'x-auth-token': Cookies.get('token'),
            };
            if(type === 'view'){
                await axios.post(`${BASE_URL}/v1/purchase-order/remove-approver`, {
                    po_name: sequence_id, approver: item,
                   
                }, { headers: headers });
            }else if(type === 'assets-view'){
                await axios.post(`${BASE_URL}/v1/asset/remove-approver`, {
                    asset_name: sequence_id, approver: item,
                   
                }, { headers: headers });
            }
            
            await getApprovalListMembers()
        } catch (error) {
            
        }
      

    }else{
        alert('Atleast one approver should be there')
    }
    
}
    const handleSave = async () => {

        const allMembersData = [...selectedMembers, ...approvalMembers].map(member => ({
            member_id: member.member_id || member._id,
            fullName: member.fullName,
            photo: member.photo,
            status: type === "po-view" ? "Pending for approval" : "Pending Approval"
        }));


        const headers = {
            'x-auth-token': Cookies.get('token'),
        };
        let endpoint, idKey, nameKey;
        if (type === 'ec-template') {
            endpoint = 'ec-template'
            idKey = 'ec_id'
            nameKey = 'sequence_id'
        } else if (type === 'ec-deviation') {
            endpoint = 'ec-deviations'
            idKey = 'dev_id'
            nameKey = 'sequence_id'
        } else if (type === 'view') {
            endpoint = 'purchase-order'
            idKey = 'po_id'
            nameKey = 'po_name'
        } else if (type === 'assets-view') {
            endpoint = 'asset'
            idKey = 'po_id'
            nameKey = 'asset_name'
        }


        await axios.post(`${BASE_URL}/v1/${endpoint}/add-more-approvals`, {
            [nameKey]: sequence_id, approvalList: allMembersData,
            [idKey]: ticketId,
        },
            { headers: headers });
        window.location.reload()
        onclose()


    };



    return (
        <div className="create-part-number-popup-page">
            <div className='create-part-num-div' >
                <div className='create-part-num-div-top'>
                    <span>Send for approval</span>
                    <PopupCloseButton

                        handleClose={onclose}
                    />
                </div>
                <div className='appoval-list-div'>
                    <span>Approval list</span>
                    <div className='approval-ec-members'>
                        {approvalMembers.map((member, index) => (
                            <div key={index} className='approval-ec-list'>

                                <NameProfile userName={member.fullName} width='28px' memberPhoto={member.photo} fontweight='500' />

                                <span>{member.fullName}</span>
                                {(type === 'view' || type === 'assets-view') &&  <img  onClick={()=>handleRemove(member)} src={`${ASSET_PREFIX_URL}cancel-detail.png`}
                                 alt="" width='24px' height='24px' style={{ cursor: 'pointer',borderRadius:'none' }} />}
                               
                            </div>

                        ))}
                    </div>
                    <div className='approval-add-more'>
                        <button onClick={toggleDropdown}>+ Add more</button>selected({selectedMembers.length})
                    </div>
                    {isDropdownOpen && (
                        <div style={{ width: '369px' }}>
                            <input type='text' placeholder='Search for members' className='add-mem-search-bar'
                                onChange={(e) => handleChange(e)} value={searchMember} />
                            {addApprovals.map((member, index) => (
                                <div
                                    key={member.entity_id}
                                    className={`part-options-child ${selectedMembers.some(selectedMember => selectedMember._id === member.entity_id) ? 'selected' : ''}`}
                                    onClick={() => handleMemberClick(member)}
                                >
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        {/* className="owner-image-span" */}
                                        <NameProfile userName={member.fullName} width='28px' memberPhoto={member.photo} fontweight='500' />
                                        <span>{member.fullName}</span>
                                    </div>
                                    <img
                                        src={selectedMembers.some(selectedMember => selectedMember._id === member.entity_id) ? `${ASSET_PREFIX_URL}part-selected.png` : `${ASSET_PREFIX_URL}part-unselect.png`}
                                        alt="Part"
                                    />
                                </div>
                            ))}

                        </div>
                    )}
                </div>
                <div className='approval-list-buttons'>
                    <button className='approval-save-btn' onClick={handleSave}>Send</button>
                    <button className='approval-cancel-btn' onClick={onclose}>Cancel</button>
                </div>
            </div>

        </div>
    )
}

export default EcApproveList