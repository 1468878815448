import React, { useState, useEffect } from "react";
import moment from 'moment';
import "./TicketAssignTemplate.css";
import Cookies from "js-cookie";
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL } from "../../constants/config";
import SearchIcon from "@mui/icons-material/Search";
import Select from "react-select";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import TemplateContainer from "./TemplateContainer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Loading from './../Common/Loading/Loading';

import { useTour } from "@reactour/tour";
import '../Common/TourGuides/TourSteps.css'

function TicketAssignTemplate({ type }) {
  const [hasData, setHasData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [assignedOptions, setAssignedOptions] = useState([]);
  const [newTemplate, setnewTemplate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(false)
  const { setIsOpen, setSteps, isOpen, setCurrentStep } = useTour();
  const [hasOpened, setHasOpened] = useState(false);
  const [isAdmin, setIsAdmin] = useState('')
  const [totalPages, setTotalPages] = useState(1);

  const handleNextClick = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handlePreviousClick = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleDone = () => {
    setIsOpen(false)
    Cookies.set("templateTourCompleted", "true", { expires: 365 });
  }

  const templateSteps = [

    {
      selector: '[data-tour="step-1"]',
      content:
        <div>
          <p>Click to Create Template</p>
          {/* <p>Click to Create Template by choosing a Department</p> */}
          <div className="tour-btns">
            <button onClick={() => handleNextClick()} className="tour-next">Next</button>
          </div>

        </div>


    },
    {
      selector: '[data-tour="step-2"]',
      content:
        <div>
          <p>Click to view the template</p>
          {/* <p>Click to view the created Templates</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">Back</button>
            <button onClick={() => handleNextClick()} className="tour-next">Next</button>
          </div>

        </div>
    },
    {
      selector: '[data-tour="step-3"]',
      content:
        <div>
          <p>Filter template by departments</p>
          {/* <p>Click to choose and Filter template by department</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">Back</button>
            <button onClick={() => handleNextClick()} className="tour-next">Next</button>
          </div>

        </div>
    },
    {
      selector: '[data-tour="step-ticket"]',
      content:
        <div>
          <p>Click to view Tickets Page</p>
          {/* <p>Click to view the list of Tickets created </p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">Back</button>
            <button onClick={() => handleDone()} className="tour-next">Done</button>
          </div>


        </div>
    },
  ];

  const emptyTemplateSteps = [

    {
      selector: '[data-tour="step-1"]',
      content: <div>
        <p>Click to Create Template</p>
        {/* <p>Click to Create Template by choosing a Department</p> */}
        <div className="tour-btns">
          <button onClick={() => handleNextClick()} className="tour-next">Next</button>
        </div>

      </div>
    },
    {
      selector: '[data-tour="step-ticket"]',
      content: <div>
        <h3>Click to view Tickets Page</h3>
        {/* <p>Click to view the list of Tickets created </p> */}
        <div className="tour-btns">
          <button onClick={() => handlePreviousClick()} className="tour-back">Back</button>
          <button onClick={() => handleDone()} className="tour-next">Done</button>
        </div>


      </div>
    },

  ];
  // console.log(currentStep+"   "+templateSteps.length)
  const nav = useNavigate();
  useEffect(() => {

    fetchDepartList();

  }, [type, searchTerm,selectedDepartment]);



  // const filterDepartments = (departments, searchTerm, selectedDepartment) => {
  //   return departments.filter((department) => {
  //     const templateName = type !== 'assets-template' ? department.templateName : department.asset_template_name;
  //     return templateName.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //       (selectedDepartment ? department.departmentNames.includes(selectedDepartment.value) : true);
  //   });


  // };

  // const filteredDepartments = filterDepartments(departmentData, searchTerm, selectedDepartment);

  useEffect(() => {
    // Extract departmentNames from departmentData and create options for Select
    const options = departmentData.reduce((acc, item) => {
      if (Array.isArray(item.departmentNames)) {
        return [...acc, ...item.departmentNames];
      } else {
        return [...acc, item.departmentNames];
      }
    }, []);

    // Remove duplicates and create unique options
    const uniqueOptions = [...new Set(options)].map((option) => ({
      value: option,
      label: option,
    }));

    setAssignedOptions(uniqueOptions);
  }, [departmentData]);

  const fetchDepartList = async () => {
    try {
      setLoading(true)
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      let response
      if (type === 'template') {
        response = await axios.get(BASE_URL + "/v1/template/get-list-temp", {
          headers,
          params: {
            page: currentPage,
            limit: 10,
            searchTerm,
            selected_department:selectedDepartment?selectedDepartment.value:''
            // department: selectedDepartment ? selectedDepartment.value : ''
          }
        });
        setTotalPages(response.data.data.pages);
      } else if (type === 'ec-template') {
        response = await axios.get(BASE_URL + "/v1/ec-template/get-list-ec-temp", { headers });
      } else if (type === 'supply-template') {
        response = await axios.get(BASE_URL + "/v1/supply-chain/get-template", { headers });
      } else if (type === 'po-template') {
        response = await axios.get(BASE_URL + "/v1/purchase-order/get-template", { headers });
      } else if (type === 'assets-template') {
        response = await axios.get(BASE_URL + "/v1/asset/get-template-list", { headers });
      }
      const requireData = response.data.data.template_list.length;
      setIsAdmin(response.data.data.is_admin)

      if (requireData > 0) {
        setHasData(true);
      } else {
        setHasData(false);
      }
      const formData = response.data.data.template_list.map((item) => ({
        ...item,
        updatedAt: new Date(item.updatedAt).toLocaleString(),
      }));

      setDepartmentData(formData);
      setLoading(false)







    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "30px",
      border: "1px solid #EDF2F7",
      padding: "0px 15px",
      boxSizing: "border-box",
      height: "40px",
    }),
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  function handlenewTemplate() {
    setnewTemplate(!newTemplate);
  }
  const itemsPerPage = 10; // You can adjust the number of items per page

  // ... existing code ...

  // const handleNextPage = () => {
  //   if (currentPage < Math.ceil(filteredDepartments.length / itemsPerPage)) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };
  const handleClick = (id) => {
    if (type === 'template') {
      nav(`/template-fields/${id}`);
    } else if (type === 'ec-template') {
      nav(`/ec-template-fields/${id}`);
    } else if (type === 'supply-template') {
      nav(`/supply-template-fields/${id}`);
    } else if (type === 'po-template') {
      nav(`/purchase-order-template-fields/${id}`);
    } else if (type === 'assets-template') {
      nav(`/assets-template-fields/${id}`);
    }

  };
  // const handleDeleteTemplate = async (event, _id) => {

  //   event.stopPropagation();
  //   try {
  //     const headers = {
  //       'x-auth-token': Cookies.get('token')
  //     };


  //     await axios.post(`${BASE_URL}/v1/template/delete-template`,
  //       { _id }
  //       , { headers });
  //     fetchDepartList();
  //   } catch (error) {
  //     console.error("Error deleting custom detail:", error);
  //   }
  // };

  // window.location.reload()
  // setSelectedDepartment('')
  // selectedDepartment.label='';
  const handleReset = () => {
    // setSelectedDepartment(null);
    setSelectedDepartment('') // Clear the selected department
  };



  const handleHoverTour = () => {
    const tourCompleted = Cookies.get("templateTourCompleted");


    if (!tourCompleted) {
      setIsOpen(true);
      setSteps(hasData ? templateSteps : emptyTemplateSteps);

      setHasOpened(true);
    }

    if (tourCompleted === "true") {
      setIsOpen(false);

    }

    // Check if the tour is closing after being opened
    if (!isOpen && hasOpened) {
      setIsOpen(false);

      Cookies.set("templateTourCompleted", "true", { expires: 365 });
    }
  }

  const formatDate = (dateString) => {
    // console.log(moment(dateString, 'D/M/YYYY').format('DD MMM YYYY'))
    return moment(dateString, 'D/M/YYYY','en').format('DD MMM YYYY');
};

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  return (

    <>

      <div className="ticket-assign-page" onMouseEnter={handleHoverTour}>
        <div className="ticket-assign-page-heading">
          <span>
            {type === 'template' && 'Tickets templates'}
            {type === 'ec-template' && 'EC templates'}
            {type === 'supply-template' && 'Supplier templates'}
            {type === 'po-template' && 'Purchase order templates'}
            {type === 'assets-template' && 'Assets templates'}
          </span>
          {/* <span>{type === 'template' ? 'Tickets templates' : 'EC templates'}</span> */}
          {/* <button onClick={() => setIsOpen(true)}>click</button> */}
          {isAdmin === true && (
            <>
              {type === 'template' && (
                 <button className="tkt-btn" onClick={handlenewTemplate} data-tour="step-1">
                  <AddOutlinedIcon />
                  {' New Template'}
               </button>
              )}
              {( type !== 'template'&&!hasData) && (
                <button className="tkt-btn" onClick={handlenewTemplate} data-tour="step-1">
                  <AddOutlinedIcon />
                  {type === 'ec-template' && ' New EC Template'}
                  {type === 'supply-template' && 'New Supplier Template'}
                  {type === 'po-template' && ' New Purchase Order Template'}
                  {type === 'assets-template' && ' New Assets Template'}
                </button>
              )}
            </>
          )}

        </div>
        <div className="ticket-assign-page-filters" >
          <div className="ticket-assign-page-filters-search">
            <SearchIcon style={{ color: "#001325" }} />
            <input
              type="text"
              placeholder="Search by template name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          {type === 'template' ? <div data-tour="step-3">
            <Select
              styles={customStyles}
              placeholder="Assigned to"
              options={assignedOptions}
              value={selectedDepartment}
              onChange={(selectedOption) => setSelectedDepartment(selectedOption)}
            />
          </div> : ''}


          {selectedDepartment && (
            <button className="reset-btn"
              onClick={handleReset}
            >reset <TuneOutlinedIcon /></button>
          )}
        </div>
        <div className="ticket-assign-table">

          <table className="ticket-assign-table-data">
            <thead>
              <tr >
                <th className="first-header">
                  Templates
                </th>
                <th className="second-header">Default version</th>
                {type === 'template' ? <th className="second-header">Assigned to</th> : ''}
                {/* <th className="second-header">Assigned to</th> */}
                <th className="fourth-header">Last modified on</th>
              </tr>
            </thead>
            {loading ? <Loading /> :
              <>
                {hasData ? (
                  <tbody>
                    {departmentData

                      .map((item) => (
                        <tr key={item.id} onClick={() => handleClick(item._id)} data-tour="step-2">
                          <td >{type !== 'assets-template' ? item.templateName : item.asset_template_name}</td>
                          <td >{`v${item.version ? item.version : '0'}`} </td>
                          {type === 'template' ? <td
                            style={{
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}

                          // Join array elements with commas and space
                          >
                            {item.departmentNames}
                          </td> : ''}

                          {/* <td
                            style={{
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}

                          // Join array elements with commas and space
                          >
                            {item.departmentNames}
                          </td> */}

                          <td className="modify-item">
                            {formatDate(item.updatedAt)}
                            {/* {moment(item.updatedAt,'D MMMM YYYY')} */}
                            <div className="modify-icons">
                              {/* <img
                                src={`${ASSET_PREFIX_URL}table-edit-icon.svg`}
                                alt=""
                                width="20px"
                                height="20px"
                                onClick={() => handleClick(item._id)}
                              /> */}
                              {/* <img
                                src={`${ASSET_PREFIX_URL}delete-icon.png`}
                                alt=""
                                width="20px"
                                height="20px"
                                onClick={(event) => handleDeleteTemplate(event,item._id)}
                              /> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>) : ""}
              </>

            }
          </table>

        </div>
        {totalPages > 1 && (
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="prev-button"
            >
              <KeyboardBackspaceIcon />
              prev
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`pagination-button ${currentPage === index + 1 ? "active" : ""
                  }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="next-button"
            >
              next
              <KeyboardBackspaceIcon
                style={{ transform: "rotate(180deg)" }}
              />
            </button>
          </div>
        )}
      </div>
      {/* // ) : (
      //   <div className="create-tkt" onMouseEnter={handleHoverTour}>
      //     <div className="tkt">
      //       <img src={`${ASSET_PREFIX_URL}ticket-template.svg`} alt="ticket-tempalte" />
      //       {type === 'template' && <span>
      //         No ticket template has been
      //         <br /> created yet
      //       </span>}
      //       {type === 'ec-template' && <span>
      //         No EC template has been
      //         <br /> created yet
      //       </span>}
      //       {type === 'supply-template' && <span>
      //         No supplier template has been
      //         <br /> created yet
      //       </span>}
      //       {type === 'po-template' && <span>
      //         No purchase order template has been
      //         <br /> created yet
      //       </span>}
      //       {type === 'assets-template' && <span>
      //         No assets template has been
      //         <br /> created yet
      //       </span>}

      //     </div>
      //     <div>
      //       {isAdmin === true && <button className="tkt-btn" onClick={handlenewTemplate} data-tour="step-1">
      //         <AddOutlinedIcon />
      //         {type === 'template' && ' New Template'}
      //         {type === 'ec-template' && ' New EC Template'}
      //         {type === 'supply-template' && ' New supply template'}
      //         {type === 'po-template' && ' New purchase order template'}
      //         {type === 'assets-template' && ' New assets template'}

      //       </button>
      //       }
      //     </div >
      //   </div >
      // )} */}

      {newTemplate && <TemplateContainer type={type} fetchDepartList={fetchDepartList}/>}
    </>



  );
}

export default TicketAssignTemplate;
