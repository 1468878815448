import React, { useState, useEffect, useRef } from "react";
import {
    BASE_URL,
    ASSET_PREFIX_URL,

} from "../../constants/config";
import { useNavigate } from "react-router-dom";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import Cookies from "js-cookie";
import axios from "axios";
 
import EcApproveList from "./EcApproveList";


function ErrorPartsPopUp({ errorParts, type, handleclose, closePartsAdd,ticketId }) {
    console.log(ticketId)
    const [partsToBeAddedErrorPop, setPartsToBeAddedErrorPop] = useState([]);
    const nav = useNavigate();

    const handleNavigateTo = (id) => {
        nav(`/part-view/${id}`);
    };

    const handleNavigateToEc = (id) => {
        window.location.pathname = `ec-ticket-view/${id}`;
    };
    useEffect(()=>{
        fecthPartsToBeAdded()
    },[])
    const fecthPartsToBeAdded = async()=>{
        try {
            // setLoading(true)
            const headers = {
              "x-auth-token": Cookies.get("token"),
            };
      
      
            const approvalResponse = await axios.get(
              `${BASE_URL}/v1/ec-template/validate-part-num-mapping`,
              {
                headers,
                params: {
                  [type === 'ec-template' ? 'ec_id' : 'dev_id']: ticketId
                }
              }
            );
      
           
           
            console.log(approvalResponse.data.data)
           
            
            //   setPartsToBeAddedPop(approvalResponse.data.data.parts_to_be_added); 
              setPartsToBeAddedErrorPop(approvalResponse.data.data.err_parts)
            //  setLoading(false)
          } catch (error) {
            console.log(error)
            // setLoading(false)
          }
    }
    return (
        <div className="notification-page">
            <div className="notification-div">
                <div className="border-bot-partsadd">
                    <div className="part-number-popup-text">
                        <span>{`${type === 'ec-template' ? 'EC' : 'Deviation'}`} cannot be approved due to the following parts</span>
                    </div>
                    <PopupCloseButton
                        handleClose={handleclose}
                    />
                </div>
                <div className="part-number-popup-border"></div>

                <table className="parts-table-errors" style={{width:'100%',padding:'0px'}}>
                    <thead>
                        <tr>
                            <th>EC ID</th>
                            <th>Part Number</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody >
                        {partsToBeAddedErrorPop.map((item, index) => (
                            <tr key={index}>
                                <td >
                                   <a href={item.sequence_id.includes('DEV')?`/deviation-ticket-view/${item.sequence_id}`:
                                `/ec-ticket-view/${item.sequence_id}`} target='_blank' className='link-to-view-ec'>{item.sequence_id}</a> 
                                    </td>
                                <td >
                                    <a href={`/part-view/${item.part_number}`} target='_blank' className='link-to-view-ec'>{item.part_number}</a></td>
                                <td>{item.approval_status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ErrorPartsPopUp;
